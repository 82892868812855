import React, { useContext, useState } from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import "./style.scss"
import { Button, Form } from "react-bootstrap"
import { useLocation, useNavigate } from "react-router-dom"
import AuthContext from "../../context/AuthContext"
import { AxiosResponse } from "axios"
import loginService from "../../services/LoginService"
import { UserRoles } from "../../shared/enums/UserRoles.enum"
import swal from "sweetalert"
import { hasRole } from "../../lib/userRoles"
import managerService from "../../services/ManagerService"
import shopkeeperService from "../../services/ShopkeeperService"

const Login = () => {
  const location = useLocation()
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [path] = useState(location.state?.path)
  const navigate = useNavigate()
  const { selectUser, markAsSigned } = useContext(AuthContext)

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    let data = {
      email,
      password,
    }

    loginService
      .login(data)
      .then((response: AxiosResponse) => {
        localStorage.setItem("hash", response.data.access_token)
        selectUser({
          id: response.data.id,
          name: response.data.name,
          roles: response.data.roles,
          picture: response.data.picture,
        })
        markAsSigned()
        const roles = +response.data.roles
        window.sessionStorage.setItem("roles", roles.toString())

        if (path) {
          navigate(path)
        } else {
          if (hasRole(roles, UserRoles.ADMIN)) {
            navigate("/locations")
          } else if (hasRole(roles, UserRoles.MANAGER)) {
            managerService.getOneByUser(+response.data.id).then((res) => {
              navigate(`/list-stores/${res.location.id}`)
            })
          } else if (hasRole(roles, UserRoles.SHOPKEEPER)) {
            shopkeeperService.getByUser(+response.data.id).then((res) => {
              window.sessionStorage.setItem("current-store", res.store.id)
              navigate("/my-store")
            })
          }
        }
      })
      .catch(() => {
        swal("Erro!", "Usuário não encontrado. Tente de novo.", "error")
      })
  }

  return (
    <div className="bg-gray">
      <div className="container container-fluid">
        <div className="login-form-div">
          <div className="login-header">
            <h1>Mapa FATECOINS</h1>
          </div>
          <div className="login-body">
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-4">
                <Form.Control
                  type="text"
                  placeholder="E-mail"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-4">
                <Form.Control
                  type="password"
                  placeholder="Senha"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Group>
              <Button type="submit" className="button-send">
                Logar
              </Button>
            </Form>
          </div>
          <div className="login-footer">
            <a href="/forgot-password">Esqueceu a senha?</a>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Login
