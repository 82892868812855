import { Button, Dropdown, Modal, Pagination, Table } from "react-bootstrap"
import Header from "../../components/Header/Header"
import "./style.scss"
import "bootstrap/dist/css/bootstrap.min.css"
import { FaEllipsisV, FaChevronLeft, FaChevronRight } from "react-icons/fa"
import { PiWarning } from "react-icons/pi"
import { useState, useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import storeService from "../../services/StoreService"
import { IStore } from "../../shared/interfaces/IStore"
import { IShopkeeper } from "../../shared/interfaces/IShopkeeper"
import userService from "../../services/UserService"
import swal from "sweetalert"
import { Status } from "../../shared/enums/Status.enum"

const ShopkeepersList = () => {
  const { storeId } = useParams()
  const navigate = useNavigate()
  const [totalPages, setTotalPages] = useState<number>()
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [shopkeepersList, setShopkeepersList] = useState<IShopkeeper[]>()
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [deleteModalShopkeeperInfo, setDeleteModalShopkeeperInfo] =
    useState<IShopkeeper>()
  const PAGE_SIZE = 10

  const loadShopkeepers = async (page: number = 1) => {
    const store: IStore = (await storeService.getById(+storeId!)).data
    const skip = (page - 1) * PAGE_SIZE
    const take = skip + PAGE_SIZE

    if (!store) return

    const activeShopkeepers = store.shopkeepers.filter(
      (shopkeeper) => shopkeeper.user.status === Status.YES
    )
    setShopkeepersList(activeShopkeepers.slice(skip, take))
    setTotalPages(Math.ceil(activeShopkeepers.length / PAGE_SIZE))
  }

  useEffect(() => {
    loadShopkeepers(currentPage)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage])

  return (
    <>
      <Header />
      <div className="bg-gray">
        <div className="shopkeepers-container container-fluid p-4">
          <div className="d-flex flex-row align-items-center justify-content-between w-100 mt-1 mb-4">
            <h2>Todos Lojistas</h2>
            <Button
              variant="primary"
              onClick={() => navigate(`/create-shopkeeper/${storeId}`)}
            >
              Novo Lojista
            </Button>
          </div>
          {!shopkeepersList || shopkeepersList.length <= 0 ? (
            <h3>Nenhum lojista encontrado</h3>
          ) : (
            <>
              <Table striped borderless hover>
                <thead>
                  <tr>
                    <th>Nome da pessoa</th>
                    <th>E-mail</th>
                    <th>Celular</th>
                  </tr>
                </thead>
                <tbody>
                  {!shopkeepersList
                    ? ""
                    : shopkeepersList.map((shopkeeper) => (
                        <tr key={shopkeeper.id}>
                          <td>{shopkeeper.user.name}</td>
                          <td>{shopkeeper.user.email}</td>
                          <td>{shopkeeper.user.phone}</td>
                          <td>
                            <Dropdown>
                              <Dropdown.Toggle
                                variant="secondary"
                                id="dropdown-basic"
                                as="span"
                                className="p-0 border-0 bg-transparent"
                              >
                                <FaEllipsisV />
                              </Dropdown.Toggle>

                              <Dropdown.Menu className="table-dropdown-menu">
                                <Dropdown.Item
                                  onClick={() => {
                                    navigate(
                                      `/edit-shopkeeper/${shopkeeper.id}`
                                    )
                                  }}
                                >
                                  Editar
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => {
                                    setDeleteModalShopkeeperInfo(shopkeeper)
                                    setShowDeleteModal(true)
                                  }}
                                >
                                  Remover
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      ))}
                </tbody>
              </Table>
              <Pagination className="mt-3 gap-2 align-items-center">
                <Pagination.Prev
                  onClick={() => {
                    setCurrentPage(Math.max(currentPage - 1, 1))
                  }}
                >
                  <FaChevronLeft />
                </Pagination.Prev>
                {[...new Array(totalPages)].map((_arr, page) => (
                  <Pagination.Item
                    key={page}
                    active={page + 1 === currentPage}
                    onClick={() => {
                      if (page + 1 !== currentPage) {
                        setCurrentPage(page + 1)
                      }
                    }}
                  >
                    {page + 1}
                  </Pagination.Item>
                ))}
                <Pagination.Next
                  onClick={() => {
                    setCurrentPage(Math.min(currentPage + 1, totalPages!))
                  }}
                >
                  <FaChevronRight />
                </Pagination.Next>
              </Pagination>
            </>
          )}
        </div>
      </div>
      <Modal
        centered
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
      >
        <Modal.Header>
          <div className="modal-icon border rounded-circle">
            <PiWarning size={24} />
          </div>
          <Modal.Title className="fw-bold">Remover lojista</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Deseja remover o lojista "
          <span className="fw-bold">
            {deleteModalShopkeeperInfo?.user.name}
          </span>
          "?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={() => setShowDeleteModal(false)}>
            CANCELAR
          </Button>
          <Button
            variant="danger"
            onClick={async () => {
              try {
                await userService.deactivateShopkeeper(
                  deleteModalShopkeeperInfo!.user.id
                )
                setShowDeleteModal(false)
                loadShopkeepers(currentPage)
                swal("Concluído!", "Lojista removido com sucesso.", "success")
              } catch {
                swal(
                  "Erro!",
                  "Ocorreu um erro ao remover o lojista. Tente de novo.",
                  "error"
                )
              }
            }}
          >
            SIM, REMOVER
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ShopkeepersList
