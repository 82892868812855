import React, { useEffect, useState } from "react"
import Header from "../../components/Header/Header"
import { UserDTO } from "../../dtos/user.dto"
import userService from "../../services/UserService"
import shopkeeperService from "../../services/ShopkeeperService"
import Joi from "joi"
import { useParams, useNavigate } from "react-router-dom"
import storeService from "../../services/StoreService"
import "./RegisterShopkeeper.scss"
import swal from "sweetalert"
import { IStore } from "../../shared/interfaces/IStore"
import { IShopkeeper } from "../../shared/interfaces/IShopkeeper"
import { UserRoles } from "../../shared/enums/UserRoles.enum"
import { AxiosError } from "axios"

interface Errors {
  shopkeeperName?: string
  shopkeeperEmail?: string
  shopkeeperPassword?: string
  shopkeeperConfirmPassword?: string
  shopkeeperPhone?: string
  shopkeeperStatus?: string
}

const RegisterShopkeeper = () => {
  const [shopkeeperName, setShopkeeperName] = useState("")
  const [shopkeeperEmail, setShopkeeperEmail] = useState<string>("")
  const [shopkeeperPassword, setShopkeeperPassword] = useState("")
  const [shopkeeperConfirmPassword, setShopkeeperConfirmPassword] = useState("")
  const [shopkeeperPhone, setShopkeeperPhone] = useState("")
  const [store, setStore] = useState<IStore | null>(null)
  const [errors, setErrors] = useState<Errors>({})
  const { storeId } = useParams<{ storeId: string }>()
  const navigate = useNavigate()

  useEffect(() => {
    const fetchStore = async () => {
      const numericLocationId = Number(storeId)
      if (!isNaN(numericLocationId)) {
        const response = await storeService.getById(numericLocationId)
        setStore(response.data)
      } else {
        console.error(`Invalid location_id: ${storeId}`)
      }
    }

    fetchStore()
  }, [storeId])

  const schema = Joi.object({
    shopkeeperName: Joi.string().required().messages({
      "string.empty": "O nome é obrigatório",
    }),

    shopkeeperEmail: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .messages({
        "string.empty": "O email é obrigatório",
        "string.email": "O email não é válido",
      }),

    shopkeeperPassword: Joi.string().min(6).required().messages({
      "string.empty": "A senha é obrigatória",
      "string.min": "A senha deve ter pelo menos 6 caracteres",
    }),

    shopkeeperConfirmPassword: Joi.string()
      .valid(Joi.ref("shopkeeperPassword"))
      .required()
      .messages({
        "string.empty": "A confirmação da senha é obrigatória",
        "any.only": "A confirmação da senha não está igual à senha digitada",
      }),

    shopkeeperPhone: Joi.string()
      .pattern(new RegExp("^[0-9]{10,11}$"))
      .optional()
      .messages({
        "string.pattern.base": "O telefone não é válido",
      }),
  })

  const validateForm = () => {
    const { error } = schema.validate(
      {
        shopkeeperName,
        shopkeeperEmail,
        shopkeeperPassword,
        shopkeeperConfirmPassword,
        shopkeeperPhone,
      },
      { abortEarly: false }
    )

    if (error) {
      setErrors(
        error.details.reduce(
          (acc: Record<string, string>, curr: Joi.ValidationErrorItem) => {
            acc[curr.path[0]] = curr.message
            return acc
          },
          {}
        )
      )
      return false
    }
    setErrors({})
    return true
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    const isValid = validateForm()

    if (!isValid) {
      swal(
        "Atenção!",
        "Todos os campos devem ser preenchidos corretamente.",
        "error"
      )
      return
    }

    const user: UserDTO = {
      name: shopkeeperName,
      email: shopkeeperEmail,
      password: shopkeeperPassword,
      phone: shopkeeperPhone,
      roles: UserRoles.SHOPKEEPER,
    }

    try {
      const newUser = await userService.create(user)

      const shopkeeper: Partial<IShopkeeper> = {
        store: store!,
        user: newUser,
      }

      await shopkeeperService.create(shopkeeper)

      setShopkeeperName("")
      setShopkeeperEmail("")
      setShopkeeperPassword("")
      setShopkeeperConfirmPassword("")
      setShopkeeperPhone("")
      swal("Concluído!", "Lojista cadastrado com sucesso.", "success").then(
        () => {
          navigate(-1)
        }
      )
    } catch (error) {
      console.error(error)
      const errorMsg =
        "Ocorreu um erro ao cadastrar o lojista. Tente de novo." +
        (error instanceof AxiosError
          ? "\nErro: " + error.response?.data.message
          : "")
      swal("Erro!", errorMsg, "error")
    }
  }

  const handleCancel = () => {
    setShopkeeperName("")
    setShopkeeperEmail("")
    setShopkeeperPassword("")
    setShopkeeperConfirmPassword("")
    setShopkeeperPhone("")
    navigate(-1)
  }

  return (
    <>
      <Header />
      <div className="bg-gray">
        <div
          className="container bg-white"
          style={{ width: "60%", margin: "0 auto" }}
        >
          <div
            className="container-fluid"
            style={{ maxWidth: "860px", paddingTop: "10px" }}
          >
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Nome completo
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder="Insira o nome do lojista"
                  value={shopkeeperName}
                  onChange={(e) => setShopkeeperName(e.target.value)}
                  onInput={() =>
                    setErrors((prev) => ({
                      ...prev,
                      shopkeeperName: undefined,
                    }))
                  }
                />
                {errors.shopkeeperName && (
                  <p style={{ color: "darkred" }}>*{errors.shopkeeperName}</p>
                )}
              </div>

              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  E-mail
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder="Insira o email do lojista"
                  value={shopkeeperEmail}
                  onChange={(e) => setShopkeeperEmail(e.target.value)}
                  onInput={() =>
                    setErrors((prev) => ({
                      ...prev,
                      shopkeeperEmail: undefined,
                    }))
                  }
                />
                {errors.shopkeeperEmail && (
                  <p style={{ color: "darkred" }}>*{errors.shopkeeperEmail}</p>
                )}
              </div>

              <div className="mb-3">
                <label htmlFor="password" className="form-label">
                  Senha
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  placeholder="Insira a senha"
                  value={shopkeeperPassword}
                  onChange={(e) => setShopkeeperPassword(e.target.value)}
                  onInput={() =>
                    setErrors((prev) => ({
                      ...prev,
                      shopkeeperPassword: undefined,
                    }))
                  }
                />
                {errors.shopkeeperPassword && (
                  <p style={{ color: "darkred" }}>
                    *{errors.shopkeeperPassword}
                  </p>
                )}
              </div>

              <div className="mb-3">
                <label htmlFor="confirmPassword" className="form-label">
                  Digite a senha de novo
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="confirmPassword"
                  placeholder="Digite a senha de novo"
                  value={shopkeeperConfirmPassword}
                  onChange={(e) => setShopkeeperConfirmPassword(e.target.value)}
                  onInput={() =>
                    setErrors((prev) => ({
                      ...prev,
                      shopkeeperConfirmPassword: undefined,
                    }))
                  }
                />
                {errors.shopkeeperConfirmPassword && (
                  <p style={{ color: "darkred" }}>
                    *{errors.shopkeeperConfirmPassword}
                  </p>
                )}
              </div>

              <div className="mb-3">
                <label htmlFor="phone" className="form-label">
                  Celular (opcional)
                </label>
                <input
                  type="tel"
                  className="form-control"
                  id="phone"
                  placeholder="Insira o número de telefone"
                  value={shopkeeperPhone}
                  onChange={(e) => setShopkeeperPhone(e.target.value)}
                  onInput={() =>
                    setErrors((prev) => ({
                      ...prev,
                      shopkeeperPhone: undefined,
                    }))
                  }
                />
                {errors.shopkeeperPhone && (
                  <p style={{ color: "darkred" }}>*{errors.shopkeeperPhone}</p>
                )}
              </div>

              <div className="mb-3">
                <button
                  type="button"
                  className="btn btn-secondary"
                  style={{ marginRight: "20px" }}
                  onClick={handleCancel}
                >
                  CANCELAR
                </button>
                <button type="submit" className="btn btn-primary">
                  CADASTRAR
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default RegisterShopkeeper
