import React, { useEffect, useState } from "react"
import Header from "../../components/Header/Header"
import { UserDTO } from "../../dtos/user.dto"
import userService from "../../services/UserService"
import managerService from "../../services/ManagerService"
import { CreateManagerDto } from "../../dtos/manager.dto"
import Joi from "joi"
import { useParams, useNavigate } from "react-router-dom"
import { LocationDto } from "../Location/dto/location.dto"
import locationService from "../../services/LocationService"
import "./RegisterResponsible.scss"
import swal from "sweetalert"
import { UserRoles } from "../../shared/enums/UserRoles.enum"

interface Errors {
  responsibleName?: string
  responsibleEmail?: string
  responsiblePassword?: string
  responsibleConfirmPassword?: string
  responsiblePhone?: string
  responsibleStatus?: string
}

const RegisterResponsible = () => {
  const [responsibleName, setResponsibleName] = useState("")
  const [responsibleEmail, setResponsibleEmail] = useState("")
  const [responsiblePassword, setResponsiblePassword] = useState("")
  const [responsibleConfirmPassword, setResponsibleConfirmPassword] =
    useState("")
  const [responsiblePhone, setResponsiblePhone] = useState("")
  const [location, setLocation] = useState<LocationDto | null>(null)
  const [errors, setErrors] = useState<Errors>({})
  const { locationId } = useParams<{ locationId: string }>()
  const navigate = useNavigate()

  useEffect(() => {
    const fetchLocation = async () => {
      const numericLocationId = Number(locationId)
      if (!isNaN(numericLocationId)) {
        const response = await locationService.getById(numericLocationId)
        setLocation(response.data)
      } else {
        console.error(`Invalid location_id: ${locationId}`)
      }
    }

    fetchLocation()
  }, [locationId])

  const schema = Joi.object({
    responsibleName: Joi.string().required().messages({
      "string.empty": "O nome é obrigatório",
    }),

    responsibleEmail: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .messages({
        "string.empty": "O email é obrigatório",
        "string.email": "O email não é válido",
      }),

    responsiblePassword: Joi.string().min(6).required().messages({
      "string.empty": "A senha é obrigatória",
      "string.min": "A senha deve ter pelo menos 6 caracteres",
    }),

    responsibleConfirmPassword: Joi.string()
      .valid(Joi.ref("responsiblePassword"))
      .required()
      .messages({
        "string.empty": "A confirmação da senha é obrigatória",
        "any.only": "A confirmação da senha não está igual à senha digitada",
      }),

    responsiblePhone: Joi.string()
      .pattern(new RegExp("^[0-9]{10,11}$"))
      .required()
      .messages({
        "string.empty": "O telefone é obrigatório",
        "string.pattern.base": "O telefone não é válido",
      }),
  })

  const validateForm = () => {
    const { error } = schema.validate(
      {
        responsibleName,
        responsibleEmail,
        responsiblePassword,
        responsibleConfirmPassword,
        responsiblePhone,
      },
      { abortEarly: false }
    )

    if (error) {
      setErrors(
        error.details.reduce(
          (acc: Record<string, string>, curr: Joi.ValidationErrorItem) => {
            acc[curr.path[0]] = curr.message
            return acc
          },
          {}
        )
      )
      return false
    }
    setErrors({})
    return true
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    const isValid = validateForm()

    if (!isValid) {
      swal(
        "Atenção!",
        "Todos os campos devem ser preenchidos corretamente.",
        "error"
      )
      return
    }

    const user: UserDTO = {
      name: responsibleName,
      email: responsibleEmail,
      password: responsiblePassword,
      phone: responsiblePhone,
      roles: UserRoles.MANAGER,
    }

    try {
      const newUser = await userService.create(user)

      const manager: CreateManagerDto = {
        locationId: Number(locationId),
        userId: newUser.id,
      }

      await managerService.create(manager)

      setResponsibleName("")
      setResponsibleEmail("")
      setResponsiblePassword("")
      setResponsibleConfirmPassword("")
      setResponsiblePhone("")
      swal("Concluído!", "Responsável cadastrado com sucesso.", "success").then(
        () => {
          navigate(-1)
        }
      )
    } catch (error) {
      console.error(error)
      swal(
        "Erro!",
        "Ocorreu um erro ao cadastrar o responsável. Tente de novo.",
        "error"
      )
    }
  }

  const handleCancel = () => {
    setResponsibleName("")
    setResponsibleEmail("")
    setResponsiblePassword("")
    setResponsibleConfirmPassword("")
    setResponsiblePhone("")
    navigate(-1)
  }

  return (
    <>
      <Header />
      <div className="bg-gray">
        <div
          className="container bg-white"
          style={{ width: "60%", margin: "0 auto" }}
        >
          <div
            className="container-fluid"
            style={{ maxWidth: "860px", paddingTop: "10px" }}
          >
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  <b>Nome do negócio</b>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="location-name"
                  disabled
                  value={location ? location.name : ""}
                />
              </div>

              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Nome completo
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder="Insira o nome do responsável"
                  value={responsibleName}
                  onChange={(e) => setResponsibleName(e.target.value)}
                  onInput={() =>
                    setErrors((prev) => ({
                      ...prev,
                      responsibleName: undefined,
                    }))
                  }
                />
                {errors.responsibleName && (
                  <p style={{ color: "darkred" }}>*{errors.responsibleName}</p>
                )}
              </div>

              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  E-mail
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder="Insira o email do responsável"
                  value={responsibleEmail}
                  onChange={(e) => setResponsibleEmail(e.target.value)}
                  onInput={() =>
                    setErrors((prev) => ({
                      ...prev,
                      responsibleEmail: undefined,
                    }))
                  }
                />
                {errors.responsibleEmail && (
                  <p style={{ color: "darkred" }}>*{errors.responsibleEmail}</p>
                )}
              </div>

              <div className="mb-3">
                <label htmlFor="password" className="form-label">
                  Senha
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  placeholder="Insira a senha"
                  value={responsiblePassword}
                  onChange={(e) => setResponsiblePassword(e.target.value)}
                  onInput={() =>
                    setErrors((prev) => ({
                      ...prev,
                      responsiblePassword: undefined,
                    }))
                  }
                />
                {errors.responsiblePassword && (
                  <p style={{ color: "darkred" }}>
                    *{errors.responsiblePassword}
                  </p>
                )}
              </div>

              <div className="mb-3">
                <label htmlFor="confirmPassword" className="form-label">
                  Digite a senha de novo
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="confirmPassword"
                  placeholder="Digite a senha de novo"
                  value={responsibleConfirmPassword}
                  onChange={(e) =>
                    setResponsibleConfirmPassword(e.target.value)
                  }
                  onInput={() =>
                    setErrors((prev) => ({
                      ...prev,
                      responsibleConfirmPassword: undefined,
                    }))
                  }
                />
                {errors.responsibleConfirmPassword && (
                  <p style={{ color: "darkred" }}>
                    *{errors.responsibleConfirmPassword}
                  </p>
                )}
              </div>

              <div className="mb-3">
                <label htmlFor="phone" className="form-label">
                  Fone
                </label>
                <input
                  type="tel"
                  className="form-control"
                  id="phone"
                  placeholder="Insira o número de telefone"
                  value={responsiblePhone}
                  onChange={(e) => setResponsiblePhone(e.target.value)}
                  onInput={() =>
                    setErrors((prev) => ({
                      ...prev,
                      responsiblePhone: undefined,
                    }))
                  }
                />
                {errors.responsiblePhone && (
                  <p style={{ color: "darkred" }}>*{errors.responsiblePhone}</p>
                )}
              </div>

              <div className="mb-3">
                <button
                  type="button"
                  className="btn btn-secondary"
                  style={{ marginRight: "20px" }}
                  onClick={handleCancel}
                >
                  CANCELAR
                </button>
                <button type="submit" className="btn btn-primary">
                  CADASTRAR
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default RegisterResponsible
