import React, { useContext, useRef, useState } from "react"
import Header from "../../components/Header/Header"
import Joi from "joi"
import { useParams, useNavigate } from "react-router-dom"
import advertisementService from "../../services/AdvertisementService"
import "./RegisterAdvertisement.scss"
import swal from "sweetalert"
import { IAdvertisement } from "../../shared/interfaces/IAdvertisement"
import AuthContext from "../../context/AuthContext"
import { AdType } from "../../shared/enums/AdType.enum"
import { CriteriaPosition } from "../../shared/enums/criteriaPosition.enum"
import { CriteriaDistancePosition } from "../../shared/enums/criteriaDistancePosition.enum"
import { IStore } from "../../shared/interfaces/IStore"
import { IUser } from "../../shared/interfaces/IUser"
import { PiUploadSimpleBold } from "react-icons/pi"

interface Errors {
  type?: string
  startDate?: string
  endDate?: string
  title?: string
  description?: string
  bannerImg?: string
  criteriaPosition?: string
  criteriaDistancePosition?: string
  criteriaDistanceMeters?: string
}

const RegisterAdvertisement = () => {
  const navigate = useNavigate()
  const { storeId } = useParams()
  const { getUser } = useContext(AuthContext)
  const user = getUser()
  const [type, setType] = useState<AdType>()
  const [startDate, setStartDate] = useState<Date>()
  const [endDate, setEndDate] = useState<Date>()
  const [title, setTitle] = useState<string>("")
  const [description, setDescription] = useState<string>()
  const [bannerImg, setBannerImg] = useState<File>()
  const [criteriaPosition, setCriteriaPosition] = useState<CriteriaPosition>()
  const [criteriaDistancePosition, setCriteriaDistancePosition] =
    useState<CriteriaDistancePosition>()
  const [criteriaDistanceMeters, setCriteriaDistanceMeters] = useState<number>()
  const [errors, setErrors] = useState<Errors>({})
  const imgInput = useRef<HTMLInputElement>(null)

  const schema = Joi.object({
    type: Joi.string()
      .valid(...Object.values(AdType))
      .required()
      .messages({
        "any.required": "Selecione um tipo de anúncio",
      }),
    startDate: Joi.date().required().messages({
      "any.required": "A data inicial é obrigatória",
    }),
    endDate: Joi.date().required().messages({
      "any.required": "A data final é obrigatória",
    }),
    title: Joi.string().required().messages({
      "string.empty": "O nome é obrigatório",
    }),
    description: Joi.string(),
    bannerImg: Joi.any().when(Joi.ref("type"), {
      is: AdType.BANNER,
      then: Joi.required().messages({
        "any.required": "A imagem é obrigatória",
      }),
    }),
    criteriaPosition: Joi.string()
      .valid(...Object.values(CriteriaPosition))
      .required()
      .messages({
        "any.required": "Selecione um critério de posição",
      }),
    criteriaDistancePosition: Joi.string()
      .valid(...Object.values(CriteriaDistancePosition))
      .when("criteriaPosition", {
        is: CriteriaPosition.POSITION,
        then: Joi.required().messages({
          "any.required": "Selecione um local",
        }),
      }),
    criteriaDistanceMeters: Joi.number()
      .valid(...[10, 25, 50])
      .when("criteriaPosition", {
        is: CriteriaPosition.DISTANCE,
        then: Joi.required().messages({
          "any.required": "Selecione uma distância",
        }),
      }),
  })

  const validateForm = () => {
    const { error } = schema.validate(
      {
        type,
        title,
        description,
        bannerImg,
        startDate,
        endDate,
        criteriaPosition,
        criteriaDistanceMeters,
        criteriaDistancePosition,
      },
      { abortEarly: false }
    )

    if (error) {
      setErrors(
        error.details.reduce(
          (acc: Record<string, string>, curr: Joi.ValidationErrorItem) => {
            acc[curr.path[0]] = curr.message
            return acc
          },
          {}
        )
      )
      return false
    }
    setErrors({})
    return true
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    const isValid = validateForm()

    if (!isValid) {
      swal(
        "Atenção!",
        "Todos os campos devem ser preenchidos corretamente.",
        "error"
      )
      return
    }

    try {
      const ad: Omit<IAdvertisement, "id" | "bannerImage"> & {
        bannerImage?: File
      } = {
        type: type!,
        title,
        description,
        startDate: startDate!,
        endDate: endDate!,
        bannerImage: bannerImg,
        criteriaDistanceMeters,
        criteriaDistancePosition,
        criteriaPosition: criteriaPosition!,
        store: { id: +storeId! } as IStore,
        user: { id: user.id! } as IUser,
      }

      await advertisementService.create(ad)

      setType(undefined)
      setTitle("")
      setStartDate(undefined)
      setEndDate(undefined)
      setBannerImg(undefined)
      setDescription("")
      setCriteriaPosition(undefined)
      setCriteriaDistanceMeters(undefined)
      setCriteriaDistancePosition(undefined)
      swal("Concluído!", "Anúncio cadastrado com sucesso.", "success").then(
        () => {
          navigate(-1)
        }
      )
    } catch (error) {
      console.error(error)
      swal(
        "Erro!",
        "Ocorreu um erro ao cadastrar o anúncio. Tente de novo.",
        "error"
      )
    }
  }

  const handleCancel = () => {
    setType(undefined)
    setTitle("")
    setStartDate(undefined)
    setEndDate(undefined)
    setBannerImg(undefined)
    setDescription("")
    setCriteriaPosition(undefined)
    setCriteriaDistanceMeters(undefined)
    setCriteriaDistancePosition(undefined)
    navigate(-1)
  }

  return (
    <>
      <Header />
      <div className="bg-gray">
        <div
          className="container bg-white"
          style={{ width: "60%", margin: "0 auto" }}
        >
          <div
            className="container-fluid"
            style={{ maxWidth: "860px", paddingTop: "10px" }}
          >
            <h3 className="mb-4">Cadastro de anúncios</h3>
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <div className="radio-button-group">
                  <div className="radio-button-container">
                    <input
                      type="radio"
                      name="type"
                      value={AdType.BANNER}
                      onChange={() => setType(AdType.BANNER)}
                      onInput={() =>
                        setErrors((prev) => ({ ...prev, type: undefined }))
                      }
                    />
                    <label>Banner</label>
                  </div>
                  <div className="radio-button-container">
                    <input
                      type="radio"
                      name="type"
                      value={AdType.PUSH}
                      onChange={() => setType(AdType.PUSH)}
                      onInput={() =>
                        setErrors((prev) => ({ ...prev, type: undefined }))
                      }
                    />
                    <label>Push notification</label>
                  </div>
                </div>
                {errors.type && (
                  <p style={{ color: "darkred" }}>*{errors.type}</p>
                )}
              </div>

              <div className="mb-3 d-flex flex-row gap-5">
                <div className="datesContainer">
                  <label htmlFor="startDate" className="form-label">
                    Data inicial
                  </label>
                  <input
                    type="date"
                    id="startDate"
                    className="form-control"
                    onChange={(e) => setStartDate(new Date(e.target.value))}
                    onInput={() =>
                      setErrors((prev) => ({ ...prev, startDate: undefined }))
                    }
                  />
                  {errors.startDate && (
                    <p style={{ color: "darkred" }}>*{errors.startDate}</p>
                  )}
                </div>
                <div className="datesContainer">
                  <label htmlFor="endDate" className="form-label">
                    Data final
                  </label>
                  <input
                    type="date"
                    id="endDate"
                    className="form-control"
                    onChange={(e) => setEndDate(new Date(e.target.value))}
                    onInput={() =>
                      setErrors((prev) => ({ ...prev, endDate: undefined }))
                    }
                  />
                  {errors.endDate && (
                    <p style={{ color: "darkred" }}>*{errors.endDate}</p>
                  )}
                </div>
              </div>

              <div className="mb-3">
                <label htmlFor="title" className="form-label">
                  Nome da campanha ou anúncio
                </label>
                <input
                  type="text"
                  id="title"
                  className="form-control"
                  placeholder="Nome da campanha"
                  onChange={(e) => setTitle(e.target.value)}
                  onInput={() =>
                    setErrors((prev) => ({ ...prev, title: undefined }))
                  }
                />
                {errors.title && (
                  <p style={{ color: "darkred" }}>*{errors.title}</p>
                )}
              </div>

              <div className="mb-3">
                <label htmlFor="description" className="form-label">
                  Descrição da campanha
                </label>
                <input
                  type="text"
                  id="description"
                  className="form-control"
                  placeholder="ex: Campanha veiculada para clientes da loja"
                  onChange={(e) => setDescription(e.target.value)}
                  onInput={() =>
                    setErrors((prev) => ({ ...prev, description: undefined }))
                  }
                />
                {errors.description && (
                  <p style={{ color: "darkred" }}>*{errors.description}</p>
                )}
              </div>

              {type === AdType.BANNER && (
                <div className="mb-3">
                  <input
                    type="file"
                    accept="image/png"
                    ref={imgInput}
                    style={{ display: "none" }}
                    onChange={(e) =>
                      setBannerImg(
                        e.target.files ? e.target.files[0] : undefined
                      )
                    }
                    onInput={() =>
                      setErrors((prev) => ({ ...prev, bannerImg: undefined }))
                    }
                  />
                  <button
                    className="btn btn-outline-primary fw-bold"
                    type="button"
                    onClick={() => imgInput.current?.click()}
                  >
                    <PiUploadSimpleBold style={{ marginRight: 5 }} />
                    BANNER
                  </button>
                  {bannerImg && (
                    <span style={{ marginLeft: "10px" }}>{bannerImg.name}</span>
                  )}
                  <br />
                  <small>
                    Insira o banner da campanha, PNG no mínimo 250x250 pixels
                  </small>
                  {errors.bannerImg && (
                    <p style={{ color: "darkred" }}>*{errors.bannerImg}</p>
                  )}
                </div>
              )}

              <div className="mb-3">
                <div className="radio-button-group">
                  <div className="radio-button-container">
                    <input
                      type="radio"
                      name="criteriaPosition"
                      value={CriteriaPosition.DISTANCE}
                      onChange={() =>
                        setCriteriaPosition(CriteriaPosition.DISTANCE)
                      }
                      onInput={() =>
                        setErrors((prev) => ({
                          ...prev,
                          criteriaPosition: undefined,
                        }))
                      }
                    />
                    <label>Distância</label>
                  </div>
                  <div className="radio-button-container">
                    <input
                      type="radio"
                      name="criteriaPosition"
                      value={CriteriaPosition.POSITION}
                      onChange={() =>
                        setCriteriaPosition(CriteriaPosition.POSITION)
                      }
                      onInput={() =>
                        setErrors((prev) => ({
                          ...prev,
                          criteriaPosition: undefined,
                        }))
                      }
                    />
                    <label>Localidade</label>
                  </div>
                </div>
                {errors.criteriaPosition && (
                  <p style={{ color: "darkred" }}>*{errors.criteriaPosition}</p>
                )}
              </div>

              {criteriaPosition === CriteriaPosition.DISTANCE ? (
                <div className="mb-4">
                  <div className="radio-button-group">
                    <div className="radio-button-container">
                      <input
                        type="radio"
                        name="criteriaDistanceMeters"
                        value={10}
                        onChange={() => setCriteriaDistanceMeters(10)}
                        onInput={() =>
                          setErrors((prev) => ({
                            ...prev,
                            criteriaDistanceMeters: undefined,
                          }))
                        }
                      />
                      <label>10 metros</label>
                    </div>
                    <div className="radio-button-container">
                      <input
                        type="radio"
                        name="criteriaDistanceMeters"
                        value={25}
                        onChange={() => setCriteriaDistanceMeters(25)}
                        onInput={() =>
                          setErrors((prev) => ({
                            ...prev,
                            criteriaDistanceMeters: undefined,
                          }))
                        }
                      />
                      <label>25 metros</label>
                    </div>
                    <div className="radio-button-container">
                      <input
                        type="radio"
                        name="criteriaDistanceMeters"
                        value={50}
                        onChange={() => setCriteriaDistanceMeters(50)}
                        onInput={() =>
                          setErrors((prev) => ({
                            ...prev,
                            criteriaDistanceMeters: undefined,
                          }))
                        }
                      />
                      <label>50 metros</label>
                    </div>
                  </div>
                  {errors.criteriaDistanceMeters && (
                    <p style={{ color: "darkred" }}>
                      *{errors.criteriaDistanceMeters}
                    </p>
                  )}
                </div>
              ) : (
                <div className="mb-4">
                  <div className="radio-button-group">
                    <div className="radio-button-container">
                      <input
                        type="radio"
                        name="criteriaDistancePosition"
                        value={CriteriaDistancePosition.STORE_FLOOR}
                        onChange={() =>
                          setCriteriaDistancePosition(
                            CriteriaDistancePosition.STORE_FLOOR
                          )
                        }
                        onInput={() =>
                          setErrors((prev) => ({
                            ...prev,
                            criteriaDistancePosition: undefined,
                          }))
                        }
                      />
                      <label>No piso da loja</label>
                    </div>
                    <div className="radio-button-container">
                      <input
                        type="radio"
                        name="criteriaDistancePosition"
                        value={CriteriaDistancePosition.PARKING}
                        onChange={() =>
                          setCriteriaDistancePosition(
                            CriteriaDistancePosition.PARKING
                          )
                        }
                        onInput={() =>
                          setErrors((prev) => ({
                            ...prev,
                            criteriaDistancePosition: undefined,
                          }))
                        }
                      />
                      <label>No estacionamento</label>
                    </div>
                    <div className="radio-button-container">
                      <input
                        type="radio"
                        name="criteriaDistancePosition"
                        value={CriteriaDistancePosition.ALL_LOCATION_BY_RADIUS}
                        onChange={() =>
                          setCriteriaDistancePosition(
                            CriteriaDistancePosition.ALL_LOCATION_BY_RADIUS
                          )
                        }
                        onInput={() =>
                          setErrors((prev) => ({
                            ...prev,
                            criteriaDistancePosition: undefined,
                          }))
                        }
                      />
                      <label>Em qualquer local</label>
                    </div>
                  </div>
                  {errors.criteriaDistancePosition && (
                    <p style={{ color: "darkred" }}>
                      *{errors.criteriaDistancePosition}
                    </p>
                  )}
                </div>
              )}

              <div className="mb-3 d-flex justify-content-center">
                <button
                  type="button"
                  className="btn btn-secondary"
                  style={{ marginRight: "20px" }}
                  onClick={handleCancel}
                >
                  CANCELAR
                </button>
                <button type="submit" className="btn btn-primary">
                  CADASTRAR
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default RegisterAdvertisement
