import React, { useEffect, useState } from "react"
import Header from "../../components/Header/Header"
import { UserDTO } from "../../dtos/user.dto"
import managerService from "../../services/ManagerService"
import { useNavigate, useParams } from "react-router-dom"
import "./EditManager.scss"
import { IManager } from "../../shared/interfaces/IManager"
import Joi from "joi"
import swal from "sweetalert"

interface Errors {
  managerName?: string
  managerEmail?: string
  managerPassword?: string
  managerConfirmPassword?: string
  managerPhone?: string
  managerStatus?: string
}

const EditManager = () => {
  const { managerId } = useParams()
  const navigate = useNavigate()
  const [managerName, setManagerName] = useState("")
  const [managerEmail, setManagerEmail] = useState("")
  const [managerPassword, setManagerPassword] = useState("")
  const [managerConfirmPassword, setManagerConfirmPassword] = useState("")
  const [managerPhone, setManagerPhone] = useState("")
  const [locationName, setLocationName] = useState("")
  const [errors, setErrors] = useState<Errors>({})

  const getManager = async () => {
    const manager: IManager = await managerService.getById(+managerId!)

    setManagerName(manager.user.name)
    setManagerEmail(manager.user.email)
    setManagerPhone(manager.user.phone ?? "")
    setLocationName(manager.location.name)
  }

  useEffect(() => {
    getManager()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [managerId])

  const schema = Joi.object({
    managerName: Joi.string().required().messages({
      "string.empty": "O nome é obrigatório",
    }),

    managerEmail: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .messages({
        "string.empty": "O email é obrigatório",
        "string.email": "O email não é válido",
      }),

    managerPassword: Joi.string().min(6).allow("").messages({
      "string.min": "A senha deve ter pelo menos 6 caracteres",
    }),
    managerConfirmPassword: Joi.string()
      .valid(Joi.ref("managerPassword"))
      .allow("")
      .messages({
        "any.only": "As senhas devem coincidir",
      }),

    managerPhone: Joi.string()
      .pattern(new RegExp("^[0-9]{10,11}$"))
      .required()
      .messages({
        "string.empty": "O telefone é obrigatório",
        "string.pattern.base": "O telefone não é válido",
      }),
  })

  const validateForm = () => {
    const { error } = schema.validate(
      {
        managerName,
        managerEmail,
        managerPassword,
        managerConfirmPassword,
        managerPhone,
      },
      { abortEarly: false }
    )

    if (managerPassword === "" && managerConfirmPassword === "") {
      if (error) {
        error.details = error.details.filter(
          (detail) =>
            detail.path[0] !== "managerPassword" &&
            detail.path[0] !== "managerConfirmPassword"
        )
      }
    }

    if (error) {
      setErrors(
        error.details.reduce(
          (acc: Record<string, string>, curr: Joi.ValidationErrorItem) => {
            acc[curr.path[0]] = curr.message
            return acc
          },
          {}
        )
      )
      return false
    }
    setErrors({})
    return true
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    const isValid = validateForm()

    if (!isValid) {
      swal(
        "Atenção!",
        "Todos os campos devem ser preenchidos corretamente.",
        "error"
      )
      return
    }

    const updatedUser: Partial<UserDTO> = {
      name: managerName,
      email: managerEmail,
      phone: managerPhone,
    }

    if (managerPassword) updatedUser.password = managerPassword

    try {
      await managerService.updateUser(+managerId!, updatedUser)

      setManagerName("")
      setManagerEmail("")
      setManagerPassword("")
      setManagerConfirmPassword("")
      setManagerPhone("")
      swal("Sucesso!", "Responsável atualizado com sucesso!", "success").then(
        () => {
          navigate(-1)
        }
      )
    } catch (error) {
      console.error(error)
      swal(
        "Erro!",
        "Ocorreu um erro ao atualizar o responsável. Tente de novo.",
        "error"
      )
    }
  }

  const handleCancel = () => {
    setManagerName("")
    setManagerEmail("")
    setManagerPassword("")
    setManagerConfirmPassword("")
    setManagerPhone("")
    setLocationName("")
    navigate(-1)
  }

  return (
    <>
      <Header />
      <div className="bg-gray">
        <div className="container containerStyle py-5 bg-white">
          <h2>Editar Responsável</h2>
          <div className="container-fluid containerFluidStyle">
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  <b>Nome do negócio</b>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="location-name"
                  required
                  disabled
                  value={locationName}
                />
              </div>

              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Nome completo
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder="Insira o nome do responsável"
                  value={managerName}
                  onChange={(e) => setManagerName(e.target.value)}
                  onInput={() =>
                    setErrors((prev) => ({ ...prev, managerEmail: undefined }))
                  }
                />
                {errors.managerName && (
                  <p style={{ color: "darkred" }}>*{errors.managerName}</p>
                )}
              </div>

              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  E-mail
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder="Insira o email do responsável"
                  value={managerEmail}
                  onChange={(e) => setManagerEmail(e.target.value)}
                  onInput={() =>
                    setErrors((prev) => ({ ...prev, managerEmail: undefined }))
                  }
                />
                {errors.managerEmail && (
                  <p style={{ color: "darkred" }}>*{errors.managerEmail}</p>
                )}
              </div>
              <span className="passwords-div-label">
                Deixe em branco para não alterar
              </span>
              <div className="passwords-div">
                <div className="mb-3">
                  <label htmlFor="password" className="form-label">
                    Senha
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    placeholder="Insira a senha"
                    value={managerPassword}
                    onChange={(e) => setManagerPassword(e.target.value)}
                    onInput={() =>
                      setErrors((prev) => ({
                        ...prev,
                        managerPassword: undefined,
                      }))
                    }
                  />
                  {errors.managerPassword && (
                    <p style={{ color: "darkred" }}>
                      *{errors.managerPassword}
                    </p>
                  )}
                </div>

                <div className="mb-3">
                  <label htmlFor="confirmPassword" className="form-label">
                    Digite a senha de novo
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="confirmPassword"
                    placeholder="Digite a senha de novo"
                    value={managerConfirmPassword}
                    onChange={(e) => setManagerConfirmPassword(e.target.value)}
                    onInput={() =>
                      setErrors((prev) => ({
                        ...prev,
                        managerConfirmPassword: undefined,
                      }))
                    }
                  />
                  {errors.managerConfirmPassword && (
                    <p style={{ color: "darkred" }}>
                      *{errors.managerConfirmPassword}
                    </p>
                  )}
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="phone" className="form-label">
                  Fone
                </label>
                <input
                  type="tel"
                  className="form-control"
                  id="phone"
                  placeholder="Insira o número de telefone"
                  value={managerPhone}
                  onChange={(e) => setManagerPhone(e.target.value)}
                  onInput={() =>
                    setErrors((prev) => ({ ...prev, managerPhone: undefined }))
                  }
                />
                {errors.managerPhone && (
                  <p style={{ color: "darkred" }}>*{errors.managerPhone}</p>
                )}
              </div>

              <div className="d-flex justify-content-center mt-5">
                <button
                  type="button"
                  className="btn btn-secondary"
                  style={{ marginRight: "20px" }}
                  onClick={handleCancel}
                >
                  CANCELAR
                </button>
                <button type="submit" className="btn btn-primary">
                  CONFIRMAR
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default EditManager
