import { Button, Dropdown, Modal, Pagination, Table } from "react-bootstrap"
import Header from "../../components/Header/Header"
import "./ListStore.scss"
import "bootstrap/dist/css/bootstrap.min.css"
import { FaEllipsisV, FaChevronLeft, FaChevronRight } from "react-icons/fa"
import { PiWarning } from "react-icons/pi"
import { useState, useEffect, useContext } from "react"
import { useNavigate, useParams } from "react-router-dom"
import locationService from "../../services/LocationService"
import { IStore } from "../../shared/interfaces/IStore"
import { ILocationData } from "../../shared/interfaces/ILocationData"
import storeService from "../../services/StoreService"
import AuthContext from "../../context/AuthContext"
import swal from "sweetalert"
import { UserRoles } from "../../shared/enums/UserRoles.enum"
import { hasRole } from "../../lib/userRoles"
import managerService from "../../services/ManagerService"

const ListStore = () => {
  const { locationId } = useParams()
  const navigate = useNavigate()
  const { getUser } = useContext(AuthContext)
  const user = getUser()
  const [locationName, setLocationName] = useState<string>()
  const [nextLocationId, setNextLocationId] = useState<number>()
  const [prevLocationId, setPrevLocationId] = useState<number>()
  const [showLocationSlider, setShowLocationSlider] = useState<boolean>(false)
  const [totalPages, setTotalPages] = useState<number>()
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [storesList, setStoresList] = useState<IStore[]>()
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [deleteModalStoreInfo, setDeleteModalStoreInfo] = useState<IStore>()
  const PAGE_SIZE = 10

  const loadStores = async (page: number = 1) => {
    const location: ILocationData = (
      await locationService.getById(+locationId!)
    ).data
    const skip = (page - 1) * PAGE_SIZE
    const take = skip + PAGE_SIZE

    if (!location) return

    if (hasRole(user.roles!, UserRoles.ADMIN)) {
      setNextLocationId(
        (await locationService.getNextLocationId(location.id!)).data.location_id
      )
      setPrevLocationId(
        (await locationService.getPrevLocationId(location.id!)).data.location_id
      )
    } else {
      setNextLocationId(
        (
          await locationService.getNextLocationIdByManager(
            location.id!,
            user.id!
          )
        ).data.location_id
      )
      setPrevLocationId(
        (
          await locationService.getPrevLocationIdByManager(
            location.id!,
            user.id!
          )
        ).data.location_id
      )
    }

    setLocationName(location.name)
    setStoresList(location.stores.slice(skip, take))
    setTotalPages(Math.ceil(location.stores.length / PAGE_SIZE))
  }

  useEffect(() => {
    if (hasRole(user.roles!, UserRoles.MANAGER)) {
      managerService.getAllByUser(+user.id!).then((res) => {
        console.log(res)
        if (res.length > 1) {
          setShowLocationSlider(true)
        } else {
          setShowLocationSlider(false)
        }
      })
    } else if (hasRole(user.roles!, UserRoles.ADMIN)) {
      setShowLocationSlider(true)
    }
  }, [user.id, user.roles])

  useEffect(() => {
    loadStores(currentPage)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage])

  function formatDate(isoDateString: string) {
    const date = new Date(isoDateString)
    const day = date.getDate().toString().padStart(2, "0")
    const month = (date.getMonth() + 1).toString().padStart(2, "0")
    const year = date.getFullYear()
    return `${day}-${month}-${year}`
  }

  return (
    <>
      <Header />
      <div className="bg-gray">
        {showLocationSlider && (
          <div className="location-nav">
            <p className="mb-2 fw-bold">Navegue pelos negócios</p>
            <div className="d-flex flex-row bg-white border rounded p-2 gap-4 mb-5">
              <a href={`/list-stores/${prevLocationId}`}>
                <FaChevronLeft />
              </a>
              <h4
                className="text-primary"
                style={
                  hasRole(user.roles!, UserRoles.ADMIN)
                    ? { cursor: "pointer" }
                    : {}
                }
                onClick={() => {
                  if (hasRole(user.roles!, UserRoles.ADMIN))
                    navigate("/locations")
                }}
              >
                {locationName}
              </h4>
              <a href={`/list-stores/${nextLocationId}`}>
                <FaChevronRight />
              </a>
            </div>
          </div>
        )}
        <div className="locations-container container-fluid p-4">
          <div className="d-flex flex-row align-items-center justify-content-between w-100 mt-1 mb-4">
            <h2>Todas as lojas</h2>
            <Button
              variant="primary"
              onClick={() => navigate(`/create-store/${locationId}`)}
            >
              Nova loja
            </Button>
          </div>
          {!storesList || storesList.length <= 0 ? (
            <h3>Nenhuma loja encontrada</h3>
          ) : (
            <>
              <Table striped borderless hover>
                <thead>
                  <tr>
                    <th>Nome da loja</th>
                    <th>Telefone</th>
                    <th>Iniciada em</th>
                    <th>Descrição</th>
                  </tr>
                </thead>
                <tbody>
                  {!storesList
                    ? ""
                    : storesList.map((store) => (
                        <tr key={store.id}>
                          <td>{store.name}</td>
                          <td>{store.phone}</td>
                          <td>{formatDate(store.startedAt)}</td>
                          <td>{store.description}</td>
                          <td>
                            <Dropdown>
                              <Dropdown.Toggle
                                variant="secondary"
                                id="dropdown-basic"
                                as="span"
                                className="p-0 border-0 bg-transparent"
                              >
                                <FaEllipsisV />
                              </Dropdown.Toggle>

                              <Dropdown.Menu className="table-dropdown-menu">
                                <Dropdown.Item
                                  onClick={() => {
                                    navigate(`/shopkeepers-list/${store.id}`)
                                  }}
                                >
                                  Ver lojistas
                                </Dropdown.Item>
                                {hasRole(user.roles!, UserRoles.ADMIN) && (
                                  <Dropdown.Item
                                    onClick={() => {
                                      navigate(`/advertisements/${store.id}`)
                                    }}
                                  >
                                    Ver anúncios
                                  </Dropdown.Item>
                                )}
                                <Dropdown.Item
                                  onClick={() => {
                                    navigate(`/edit-store/${store.id}`)
                                  }}
                                >
                                  Editar
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => {
                                    setDeleteModalStoreInfo(store)
                                    setShowDeleteModal(true)
                                  }}
                                >
                                  Remover
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      ))}
                </tbody>
              </Table>
              <Pagination className="mt-3 gap-2 align-items-center">
                <Pagination.Prev
                  onClick={() => {
                    setCurrentPage(Math.max(currentPage - 1, 1))
                  }}
                >
                  <FaChevronLeft />
                </Pagination.Prev>
                {[...new Array(totalPages)].map((_arr, page) => (
                  <Pagination.Item
                    key={page}
                    active={page + 1 === currentPage}
                    onClick={() => {
                      if (page + 1 !== currentPage) {
                        setCurrentPage(page + 1)
                      }
                    }}
                  >
                    {page + 1}
                  </Pagination.Item>
                ))}
                <Pagination.Next
                  onClick={() => {
                    setCurrentPage(Math.min(currentPage + 1, totalPages!))
                  }}
                >
                  <FaChevronRight />
                </Pagination.Next>
              </Pagination>
            </>
          )}
        </div>
      </div>
      <Modal
        centered
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
      >
        <Modal.Header>
          <div className="modal-icon border rounded-circle">
            <PiWarning size={24} />
          </div>
          <Modal.Title className="fw-bold">Remover loja</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Deseja remover o loja "
          <span className="fw-bold">{deleteModalStoreInfo?.name}</span>
          "?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={() => setShowDeleteModal(false)}>
            CANCELAR
          </Button>
          <Button
            variant="danger"
            onClick={async () => {
              try {
                await storeService.delete(deleteModalStoreInfo!.id)
                setShowDeleteModal(false)
                loadStores(currentPage)
                swal("Concluído!", "Loja removida com sucesso.", "success")
              } catch {
                swal(
                  "Erro!",
                  "Ocorreu um erro ao remover o loja. Tente de novo.",
                  "error"
                )
              }
            }}
          >
            SIM, REMOVER
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ListStore
