import api from "./api"

class PointService {
  async getByLocation(locationId: number) {
    return api({
      url: `${process.env.REACT_APP_API_URL}points/location/${locationId}`,
      method: "GET",
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("hash")}`,
      },
    })
      .then((result) => {
        return Promise.resolve(result.data)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }
  async getStoreByLocationAndPoint(locationId: number, pointId: number) {
    return api({
      url: `${process.env.REACT_APP_API_URL}points/${locationId}/${pointId}`,
      method: "GET",
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("hash")}`,
      },
    })
      .then((result) => {
        return Promise.resolve(result.data)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  async update(pointId: number, newPointName: string) {
    return api
      .patch(`/points/${pointId}`, { name: newPointName })
      .then((result) => Promise.resolve(result))
      .catch((error) => Promise.reject(error))
  }

  async linkStore(pointId: number, storeId: number) {
    return api
      .patch(`/points/${pointId}`, { store: { id: storeId } })
      .then((result) => Promise.resolve(result))
      .catch((error) => Promise.reject(error))
  }
}

const pointService = new PointService()
export default pointService
