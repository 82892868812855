
import React, { useState, useEffect } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import MapComponent from '../../components/Map/Map';
import locationService from '../../services/LocationService';
import { ILocationData } from '../../shared/interfaces/ILocationData';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import Header from '../../components/Header/Header';

const MapList: React.FC = () => {
  const [locations, setLocations] = useState<ILocationData[]>([]);
  const [currentLocation, setCurrentLocation] = useState<ILocationData | null>(null);
  const [totalPages, setTotalPages] = useState<number>();
  
  const loadLocations = async (page: number = 1) => {
    const { data, total } = (await locationService.getAll(page, 10)).data;
    setLocations(data);
    setTotalPages(total);
    if (data.length > 0) {
      setCurrentLocation(data[0]);
    }
  };
  
  useEffect(() => {
    loadLocations();
  }, []);


  const handleNext = () => {
    if (currentLocation && typeof currentLocation.id === 'number') {
      const currentIndex = locations.findIndex(location => location.id === currentLocation.id);
      if (currentIndex !== -1) {
        const nextIndex = currentIndex + 1;
        if (nextIndex < locations.length) {
          setCurrentLocation(locations[nextIndex]);
        } else {
          console.error('No more locations available');
        }
      } else {
        console.error('Current location not found in locations array');
      }
    } else {
      console.error(`Current location ID is not a valid number: ${currentLocation?.id} (type: ${typeof currentLocation?.id})`);
    }
  };

  const handlePrev = () => {
    if (currentLocation && typeof currentLocation.id === 'number') {
      const currentIndex = locations.findIndex(location => location.id === currentLocation.id);
      if (currentIndex !== -1) {
        const prevIndex = currentIndex - 1;
        if (prevIndex >= 0) {
          setCurrentLocation(locations[prevIndex]);
        } else {
          console.error('No previous locations available');
        }
      } else {
        console.error('Current location not found in locations array');
      }
    } else {
      console.error(`Current location ID is not a valid number: ${currentLocation?.id} (type: ${typeof currentLocation?.id})`);
    }
  };

  return (
    <div>
      <Header />
      <div className="bg-gray mt-5 pt-4">
        <div className="location-nav">
          <p className="mb-2 fw-bold">Navegue pelos negócios</p>
          <div className="d-flex flex-row bg-white border rounded p-2 gap-4 mb-4">
            <a onClick={handlePrev} style={{ cursor: "pointer" }}>
              <FaChevronLeft />
            </a>
            {currentLocation && (
              <h4 className="text-primary">
                {currentLocation.name}
              </h4>
            )}
            <a onClick={handleNext} style={{ cursor: "pointer" }}>
              <FaChevronRight />
            </a>
          </div>
        </div>

        <div className="locations-container container-fluid p-2">
          <div className="d-flex flex-row align-items-center justify-content-between w-100 mt-1 mb-4">
            {currentLocation ? (
              <MapComponent location={currentLocation} />
            ) : (
              <LoadingSpinner />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MapList;
