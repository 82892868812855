import React, { useEffect, useState } from "react"
import Header from "../../components/Header/Header"
import locationService from "../../services/LocationService"
import "bootstrap-icons/font/bootstrap-icons.css"
import "./EditLocation.scss"
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner"
import { useNavigate, useParams } from "react-router-dom"
import { ILocationData } from "../../shared/interfaces/ILocationData"
import Joi from "joi"
import swal from "sweetalert"

interface Errors {
  name?: string
  cep?: string
  address?: string
  addressNumber?: string
}

const EditLocation = () => {
  const { locationId } = useParams()
  const [cep, setCep] = useState("")
  const [address, setAddress] = useState("")
  const [state, setState] = useState("")
  const [city, setCity] = useState("")
  const [name, setName] = useState("")
  const [addressNumber, setAddressNumber] = useState("")
  const [addressAdditional, setAddressAdditional] = useState("")
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [errors, setErrors] = useState<Errors>({})
  const navigate = useNavigate()

  const getLocation = async () => {
    const location: ILocationData = (
      await locationService.getById(+locationId!)
    ).data

    setName(location.name)
    setCep(location.cep)
    setAddress(location.address)
    setAddressAdditional(location.addressAdditional)
    setCity(location.addressCity)
    setAddressNumber(location.addressNumber ?? "")
    setState(location.addressState)
  }

  useEffect(() => {
    getLocation()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationId])

  if (isLoading) {
    return <LoadingSpinner />
  }

  const schema = Joi.object({
    name: Joi.string().required().messages({
      "string.empty": "O nome é obrigatório",
    }),
    cep: Joi.string()
      .pattern(new RegExp("^[0-9]{5}-?[0-9]{3}$"))
      .required()
      .messages({
        "string.empty": "O CEP é obrigatório",
        "string.pattern.base": "O CEP é inválido",
      }),
    address: Joi.string().required().messages({
      "string.empty": "O endereço é obrigatório",
    }),
    addressNumber: Joi.string().required().messages({
      "string.empty": "O número é obrigatório",
    }),
  })

  const validateForm = () => {
    const { error } = schema.validate(
      {
        name,
        cep,
        address,
        addressNumber,
      },
      { abortEarly: false }
    )

    if (error) {
      setErrors(
        error.details.reduce(
          (acc: Record<string, string>, curr: Joi.ValidationErrorItem) => {
            acc[curr.path[0]] = curr.message
            return acc
          },
          {}
        )
      )
      return false
    }
    setErrors({})
    return true
  }

  const handleCepChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCep(event.target.value)

    const resultViaCep = await locationService.getAdressViaCep(
      event.target.value
    )

    setAddress(resultViaCep.logradouro)
    setState(resultViaCep.uf)
    setCity(resultViaCep.localidade)
    setAddressAdditional(resultViaCep.complemento)
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    const isValid = validateForm()

    if (!isValid) {
      swal(
        "Atenção!",
        "Todos os campos devem ser preenchidos corretamente.",
        "error"
      )
      return
    }

    setIsLoading(true)
    const locationData = {
      name,
      cep,
      address,
      addressNumber,
      addressState: state,
      addressCity: city,
      addressAdditional,
    }

    try {
      await locationService.update(+locationId!, locationData)

      setName("")
      setCep("")
      setAddress("")
      setAddressAdditional("")
      setCity("")
      setAddressNumber("")
      setState("")
      navigate(-1)
      swal("Concluído!", "Negócio atualizado com sucesso.", "success")
    } catch (error) {
      console.error(error)
      swal(
        "Erro!",
        "Ocorreu um erro ao atualizar o negócio. Tente de novo.",
        "error"
      )
    } finally {
      setIsLoading(false)
    }
  }

  const handleCancel = () => {
    setName("")
    setCep("")
    setAddress("")
    setAddressAdditional("")
    setCity("")
    setAddressNumber("")
    setState("")
    setErrors({})

    navigate(-1)
  }

  return (
    <>
      <Header />
      <div className="bg-gray">
        <div
          className="container py-5 bg-white"
          style={{ width: "60%", margin: "0 auto" }}
        >
          <div className="container-fluid" style={{ maxWidth: "860px" }}>
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="businessName" className="form-label">
                  Nome do Negócio
                </label>
                <input
                  type="text"
                  id="businessName"
                  className="form-control input-light-placeholder"
                  placeholder="Insira o nome do negócio"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  onInput={() =>
                    setErrors((prev) => ({ ...prev, name: undefined }))
                  }
                />
                {errors.name && (
                  <p style={{ color: "darkred" }}>*{errors.name}</p>
                )}
              </div>

              <div className="mb-3">
                <label htmlFor="cep" className="form-label">
                  CEP
                </label>
                <input
                  type="text"
                  id="cep"
                  className="form-control input-light-placeholder"
                  placeholder="00000-000"
                  value={cep}
                  onChange={(event) => setCep(event.target.value)}
                  onBlur={(event) => {
                    const cepPattern = /^\d{8}$|^\d{5}-\d{3}$/
                    if (event.target.value.trim() === "") {
                      setErrors((prev) => ({
                        ...prev,
                        cep: "O CEP é obrigatório",
                      }))
                      document.getElementById("cep")?.focus()
                    } else if (cepPattern.test(event.target.value)) {
                      handleCepChange(event)
                    } else {
                      setErrors((prev) => ({
                        ...prev,
                        cep: "Formato de CEP inválido",
                      }))
                      document.getElementById("cep")?.focus()
                    }
                  }}
                  onInput={() =>
                    setErrors((prev) => ({ ...prev, cep: undefined }))
                  }
                />
                {errors.cep && (
                  <p style={{ color: "darkred" }}>*{errors.cep}</p>
                )}
              </div>

              <div className="row mb-3">
                <div className="col">
                  <label htmlFor="address" className="form-label">
                    Endereço
                  </label>
                  <input
                    type="text"
                    id="address"
                    className="form-control input-light-placeholder"
                    placeholder="Rua, avenida, etc."
                    value={address}
                    onChange={(event) => setAddress(event.target.value)}
                    onInput={() =>
                      setErrors((prev) => ({ ...prev, address: undefined }))
                    }
                  />
                  {errors.address && (
                    <p style={{ color: "darkred" }}>*{errors.address}</p>
                  )}
                </div>

                <div className="col-2">
                  <label htmlFor="number" className="form-label">
                    Número
                  </label>
                  <input
                    type="text"
                    id="number"
                    className="form-control input-light-placeholder"
                    placeholder="Número do local"
                    value={addressNumber}
                    onChange={(e) => setAddressNumber(e.target.value)}
                    onInput={() =>
                      setErrors((prev) => ({
                        ...prev,
                        addressNumber: undefined,
                      }))
                    }
                  />
                  {errors.addressNumber && (
                    <p style={{ color: "darkred" }}>*{errors.addressNumber}</p>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-1">
                  <label htmlFor="state" className="form-label">
                    Estado
                  </label>
                  <input
                    type="text"
                    id="state"
                    className="form-control input-light-placeholder"
                    value={state}
                    readOnly
                    disabled
                    onChange={(e) => setState(e.target.value)}
                  />
                </div>

                <div className="col">
                  <label htmlFor="city" className="form-label">
                    Cidade
                  </label>
                  <input
                    type="text"
                    id="city"
                    className="form-control input-light-placeholder"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    readOnly
                    disabled
                  />
                </div>

                <div className="col">
                  <label htmlFor="complement" className="form-label">
                    Complemento (opcional)
                  </label>
                  <input
                    type="text"
                    id="complement"
                    className="form-control input-light-placeholder"
                    placeholder="Insira um complemento"
                    value={addressAdditional}
                    onChange={(e) => setAddressAdditional(e.target.value)}
                  />
                </div>
              </div>

              <div className="d-flex justify-content-center mt-5">
                <button
                  type="button"
                  className="btn btn-secondary"
                  style={{ marginRight: "20px" }}
                  onClick={handleCancel}
                >
                  CANCELAR
                </button>
                <button type="submit" className="btn btn-primary">
                  CONFIRMAR
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default EditLocation
