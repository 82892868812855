import axios from "axios"
import tokenService from "./TokenService"

axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*"
axios.defaults.headers.put["Access-Control-Allow-Origin"] = "*"
axios.defaults.headers.get["Access-Control-Allow-Origin"] = "*"

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

api.interceptors.request.use(async (config: any) => {
  if (config.url !== "/users/login") {
    let token = localStorage.getItem("hash")
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`
    }
  }
  return config
})

api.interceptors.response.use(
  (response) => {
    return response
  },
  (err) => {
    return new Promise((resolve, reject) => {
      const originalReq = err.config

      if (
        err.response.status === 401 &&
        err.config &&
        !err.config._retry &&
        !err.config.url.endsWith("login")
      ) {
        originalReq._retry = true
        let token = localStorage.getItem("hash")
        if (token)
          return tokenService
            .refresh(token)
            .then((res) => {
              if (res.data.access_token) {
                localStorage.setItem("hash", res.data.access_token)
              }
              originalReq.headers[
                "Authorization"
              ] = `Bearer ${res.data.access_token}`
              const req = axios(originalReq)
              resolve(req)
            })
            .catch((error) => console.error(error))
      } else {
        reject(err)
      }
    })
  }
)

export default api
