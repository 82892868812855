import { UserRoles } from "../shared/enums/UserRoles.enum"

/**
 * Checks whether the user has all the specified roles.
 * @param userRoles - The current bitmask representing the user's roles.
 * @param roles - The bitmask of the roles to check.
 * @returns `true` if the user has all the specified roles, `false` otherwise.
 * @example
 * const userRoles = ROLES.ADMIN | ROLES.MANAGER; // 3 (binary 0011)
 * const result = hasRole(userRoles, ROLES.ADMIN); // true
 * const result = hasRole(userRoles, ROLES.ADMIN | ROLES.SHOPKEEPER); // false
 */
export function hasRole(userRoles: number, roles: number): boolean {
  return (userRoles & roles) === roles
}

/**
 * Checks whether the user has any of the specified roles.
 * @param userRoles - The current bitmask representing the user's roles.
 * @param roles - The bitmask of the roles to check.
 * @returns `true` if the user has at least one of the specified roles, `false` otherwise.
 * @example
 * const userRoles = ROLES.ADMIN | ROLES.MANAGER; // 3 (binary 0011)
 * const result = hasAnyRole(userRoles, ROLES.ADMIN); // true
 * const result = hasAnyRole(userRoles, ROLES.SHOPKEEPER); // false
 * const result = hasAnyRole(userRoles, ROLES.ADMIN | ROLES.SHOPKEEPER); // true
 */
export function hasAnyRole(userRoles: number, roles: number): boolean {
  return (userRoles & roles) !== 0
}

/**
 * Adds a role to the user's roles bitmask.
 * @param userRoles - The current bitmask representing the user's roles.
 * @param role - The bitmask of the role to add.
 * @returns A new bitmask with the specified role added.
 * @example
 * let userRoles = ROLES.ADMIN; // 1 (binary 0001)
 * userRoles = addRole(userRoles, ROLES.MANAGER); // 3 (binary 0011)
 * userRoles = addRole(userRoles, ROLES.SHOPKEEPER); // 7 (binary 0111)
 */
export function addRole(userRoles: number, role: UserRoles): number {
  return userRoles | role
}

/**
 * Removes a role from the user's roles bitmask.
 * @param userRoles - The current bitmask representing the user's roles.
 * @param role - The bitmask of the role to remove.
 * @returns A new bitmask with the specified role removed.
 * @example
 * let userRoles = ROLES.ADMIN | ROLES.MANAGER | ROLES.SHOPKEEPER; // 7 (binary 0111)
 * userRoles = removeRole(userRoles, ROLES.MANAGER); // 5 (binary 0101)
 * userRoles = removeRole(userRoles, ROLES.ADMIN); // 4 (binary 0100)
 */
export function removeRole(userRoles: number, role: UserRoles): number {
  return userRoles & ~role
}
