import React, { useEffect, useRef } from "react"
import { Feature, Map as OLMap, View } from "ol"
import TileLayer from "ol/layer/Tile"
import OSM from "ol/source/OSM"
import "ol/ol.css"
import { useGeographic } from "ol/proj"
import VectorSource from "ol/source/Vector"
import VectorLayer from "ol/layer/Vector"
import Point from "ol/geom/Point"
import Style from "ol/style/Style"
import Icon from "ol/style/Icon"
import Select from "ol/interaction/Select"
import { ILocationData } from "../../shared/interfaces/ILocationData"
import IPoint from "../../shared/interfaces/IPoint"
import pointService from "../../services/PointService"
import $ from "jquery"
import "./Map.scss"
import { IStore } from "../../shared/interfaces/IStore"
import { createRoot } from "react-dom/client"
import { StorePicker } from "../StorePicker/StorePicker"

interface MapComponentProps {
  location: ILocationData
}

interface PopupData {
  x: number
  y: number
  point: IPoint
}

const MapComponent: React.FC<MapComponentProps> = ({ location }) => {
  const mapRef = useRef<HTMLDivElement | null>(null)
  const popupRef = useRef<HTMLDivElement | null>(null)
  const pointDataMap = useRef<Map<number, IPoint>>(new Map())

  useGeographic()

  useEffect(() => {
    let newStore: IStore | undefined = undefined

    if (mapRef.current) {
      const osmLayer = new TileLayer({
        source: new OSM(),
      })

      const map = new OLMap({
        target: mapRef.current,
        layers: [osmLayer],
        view: new View({
          center: [location.longitude || 0, location.latitude || 0],
          zoom: 18,
        }),
      })

      const vectorSource = new VectorSource()
      const vectorLayer = new VectorLayer({
        source: vectorSource,
      })
      map.addLayer(vectorLayer)

      pointService
        .getByLocation(+location.id!)
        .then((points: IPoint[]) => {
          points.forEach((point: IPoint) => {
            const iconFeature = new Feature({
              geometry: new Point([point.longitude, point.latitude]),
              id: point.id,
            })

            const iconStyle = new Style({
              image: new Icon({
                scale: [0.75, 0.75],
                anchor: [0.5, 1],
                anchorXUnits: "fraction",
                anchorYUnits: "fraction",
                src: process.env.PUBLIC_URL + "/red-marker.png",
              }),
            })

            iconFeature.setStyle(iconStyle)
            vectorSource.addFeature(iconFeature)

            pointDataMap.current.set(point.id, point)
          })
        })
        .catch((error) => {
          console.error(error)
        })

      const selectInteraction = new Select()
      map.addInteraction(selectInteraction)

      selectInteraction.on("select", async (evt) => {
        if (evt.selected.length === 0) {
          console.log("No feature selected")
          return
        }

        const selectedFeature = evt.selected[0]
        const id = selectedFeature.get("id")
        const pointData = pointDataMap.current.get(id)

        if (!pointData) {
          console.log("No point data found for ID:", id)
          return
        }

        try {
          const pointStore = await pointService.getStoreByLocationAndPoint(
            +location.id!,
            id
          )

          if (pointStore) {
            pointData.store = pointStore.store
            const clickPosition = evt.mapBrowserEvent.pixel
            showPopup({
              x: clickPosition[0],
              y: clickPosition[1],
              point: pointData,
            })
          } else {
            console.error("pointStore is undefined or null")
          }
        } catch (error) {
          console.error("Error fetching pointStore:", error)
        }
      })

      const showPopup = ({ x, y, point }: PopupData) => {
        const popupElement = $("#popup")

        if (popupElement) {
          popupElement.css({
            top: y - 50,
            left: x - (popupElement.outerWidth() || 0) / 2,
            border: "none",
            borderRadius: "10px",
            padding: "10px 20px 20px",
          })
        }

        showViewPointHtml(point)
        $("#popup").show()
      }

      const showViewPointHtml = (point: IPoint) => {
        $("#popup-content").html(`
          <div style="text-align: center;">
            <span style="font-size: 14px;">${point.name}</span><br />
          </div>
          <hr />
          <span style="font-size: 14px;">${point.store?.name}</span><br />
          <span style="font-size: 14px;"><span style="font-weight: 300;">Email:</span> ${point.store?.email}</span><br />
          <span style="font-size: 14px;"><span style="font-weight: 300;">Telefone:</span> ${point.store?.phone}</span><br />
          <div style="text-align: center; margin-top: 20px;">
            <button id="edit-button" class="btn btn-primary" style="padding: 5px 20px; font-size: 16px; width: 100%; box-sizing: border-box;">Editar</button>
          </div>
        `)
        $("#edit-button").on("click", () => {
          showEditHtml(point)
        })
      }

      const showEditHtml = (point: IPoint) => {
        $("#popup-content").html(`
          <div style="text-align: center; margin-bottom: 10px;">
            <span style="font-size: 14px;">Editando ponto: ${point.name}</span><br />
          </div>
          <input type="radio" id="info" name="tab" checked>
          <input type="radio" id="loja" name="tab">
          <div class="tab-buttons">
            <label for="info">Informações do ponto</label>
            <label for="loja">Loja</label>
          </div>
          <div class="content">
            <div class="info-content">
              <div class="form-group">
                <label for="nomeDoPonto">Nome do ponto</label>
                <input type="text" id="nomeDoPonto" placeholder="Nome do ponto" value="${point.name}">
              </div>
            </div>
            <div class="loja-content">
              <div id="store-picker-div" class="form-group"></div>
            </div>
          </div>
          <div class="d-flex gap-3">
            <button id="cancel-button" class="btn btn-secondary" style="padding: 5px 20px; font-size: 14px; box-sizing: border-box;">CANCELAR</button>
            <button id="save-button" class="btn btn-primary" style="padding: 5px 20px; font-size: 12px; width: 100%; box-sizing: border-box;"></button>
          </div>
        `)

        const storePickerRoot = createRoot(
          document.getElementById("store-picker-div")!
        )

        $("#loja").on("change", () => {
          storePickerRoot.render(
            <>
              <label htmlFor="store">Selecione a loja</label>
              <StorePicker
                id="store-picker"
                locationId={location.id!}
                defaultValue={point.store}
                onChange={(store) => {
                  newStore = store
                }}
              />
            </>
          )
        })

        $("#cancel-button").on("click", () => {
          showViewPointHtml(point)
        })

        $("#save-button").on("click", () => {
          if ($("#info").is(":checked")) {
            const newPointName = $("#nomeDoPonto").val() as string
            pointService.update(point.id, newPointName).then(() => {
              point.name = newPointName
              showViewPointHtml(point)
            })
          } else if ($("#loja").is(":checked")) {
            if (newStore) {
              pointService.linkStore(point.id, newStore.id).then(() => {
                point.store = newStore
                showViewPointHtml(point)
              })
            }
          }
        })
      }

      const closePopup: () => void = () => {
        $("#popup").hide()
        if (selectInteraction) {
          selectInteraction.getFeatures().clear()
        }
      }

      map.on("click", function () {
        closePopup()
      })

      map.on("movestart", function () {
        closePopup()
      })

      return () => {
        map.setTarget("")
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  return (
    <>
      <div style={{ height: "720px", width: "100%" }} ref={mapRef} />
      <div id="popup" ref={popupRef} className="popup">
        <div id="popup-content"></div>
      </div>
    </>
  )
}

export default MapComponent
