import { Navbar, Nav, Dropdown, Image } from "react-bootstrap"
import "./style.scss"
import { useContext, useEffect, useState } from "react"
import AuthContext from "../../context/AuthContext"
import { FaChevronDown } from "react-icons/fa"
import { UserRoles } from "../../shared/enums/UserRoles.enum"
import { hasAnyRole, hasRole } from "../../lib/userRoles"
import locationService from "../../services/LocationService"
import managerService from "../../services/ManagerService"

const Header = () => {
  const { getUser } = useContext(AuthContext)
  const user = getUser()
  const currentStoreId = window.sessionStorage.getItem("current-store")
  const [firstLocationId, setFirstLocationId] = useState<number>()

  const getFirstLocation = async () => {
    if (hasRole(user.roles!, UserRoles.ADMIN)) {
      const { data } = (await locationService.getAll(1, 10)).data
      setFirstLocationId(data[0].id)
    } else if (hasRole(user.roles!, UserRoles.MANAGER)) {
      setFirstLocationId(
        (await managerService.getOneByUser(+user.id!)).location.id
      )
    }
  }

  useEffect(() => {
    getFirstLocation()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Navbar bg="white" expand="lg" fixed="top" className="shadow-sm flex-row">
      <Navbar.Brand className="text-primary ms-5">MAPA</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" className="me-5" />
      <Navbar.Collapse id="basic-navbar-nav" className="justify-content-center">
        <Nav className="mx-auto">
          {hasRole(user.roles!, UserRoles.ADMIN) && (
            <Nav.Link
              href="/locations"
              className={`mx-3 ${
                window.location.pathname === "/locations" ? "selected-nav" : ""
              }`}
              active={window.location.pathname === "/locations"}
            >
              Negócios
            </Nav.Link>
          )}
          {hasAnyRole(user.roles!, UserRoles.MANAGER | UserRoles.ADMIN) && (
            <Nav.Link
              href={`/list-stores/${firstLocationId}`}
              className={`mx-3 ${
                window.location.pathname.includes("/list-stores")
                  ? "selected-nav"
                  : ""
              }`}
              active={window.location.pathname.includes("/list-stores")}
            >
              Lojas
            </Nav.Link>
          )}
          {hasRole(user.roles!, UserRoles.SHOPKEEPER) && (
            <>
              <Nav.Link
                href="/my-store"
                className={`mx-3 ${
                  window.location.pathname === "/my-store" ? "selected-nav" : ""
                }`}
                active={window.location.pathname === "/my-store"}
              >
                Minha Loja
              </Nav.Link>
              <Nav.Link
                href={`/advertisements/${currentStoreId}`}
                className={`mx-3 ${
                  window.location.pathname.includes("/advertisements")
                    ? "selected-nav"
                    : ""
                }`}
                active={window.location.pathname.includes("/advertisements")}
              >
                Anúncios
              </Nav.Link>
            </>
          )}
          {hasAnyRole(user.roles!, UserRoles.ADMIN | UserRoles.MANAGER) && (
            <Nav.Link
              href="/map-list"
              className={`mx-3 ${
                window.location.pathname === "/map-list" ? "selected-nav" : ""
              }`}
              active={window.location.pathname === "/map-list"}
            >
              Mapa
            </Nav.Link>
          )}
        </Nav>
        <Nav id="profile" className="me-5 align-items-center gap-2 flex-row">
          {user.picture && (
            <Image src={user.picture} roundedCircle height="40" />
          )}
          <Dropdown title="" id="basic-nav-dropdown" align={"start"}>
            <Dropdown.Toggle
              variant="secondary"
              id="header-dropdown"
              as="span"
              className="p-0 border-0 bg-transparent"
            >
              <span>{user.name} </span>
              <FaChevronDown />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item href="/change-password">
                Alterar senha
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item href="/" className="text-danger">
                Logout
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}

export default Header
