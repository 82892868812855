import api from "./api"
import { IAdvertisement } from "../shared/interfaces/IAdvertisement"
import { Status } from "../shared/enums/Status.enum"

class AdvertisementService {
  async create(
    advertData: Omit<IAdvertisement, "id" | "bannerImage"> & {
      bannerImage?: File
    }
  ) {
    return api({
      url: process.env.REACT_APP_API_URL + "advertisements",
      method: "POST",
      data: advertData,
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("hash")}`,
      },
    })
      .then((result) => {
        return Promise.resolve(result.data)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  async getById(id: number) {
    return api
      .get(`/advertisements/${id}`)
      .then((result) => Promise.resolve(result))
      .catch((error) => Promise.reject(error))
  }

  async getByStore(storeId: number) {
    return api
      .get(`/advertisements/store/${storeId}`)
      .then((result) => Promise.resolve(result))
      .catch((error) => Promise.reject(error))
  }

  async update(
    advertId: number,
    advertData: Partial<
      Omit<IAdvertisement, "id" | "bannerImage"> & { bannerImage: File }
    >
  ) {
    return api({
      url: process.env.REACT_APP_API_URL + `advertisements/${advertId}`,
      method: "PATCH",
      data: advertData,
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("hash")}`,
      },
    })
      .then((result) => {
        return Promise.resolve(result.data)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  async deactivate(advertId: number) {
    return api
      .patch(`/advertisements/${advertId}`, { status: Status.NO })
      .then((result) => Promise.resolve(result))
      .catch((error) => Promise.reject(error))
  }
}

const advertisementService = new AdvertisementService()
export default advertisementService
