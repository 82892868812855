import { Button, Dropdown, Modal, Pagination, Table } from "react-bootstrap"
import Header from "../../components/Header/Header"
import "./style.scss"
import "bootstrap/dist/css/bootstrap.min.css"
import { FaEllipsisV, FaChevronLeft, FaChevronRight } from "react-icons/fa"
import { PiWarning } from "react-icons/pi"
import { useState, useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { IAdvertisement } from "../../shared/interfaces/IAdvertisement"
import advertisementService from "../../services/AdvertisementService"
import swal from "sweetalert"

const AdvertisementsList = () => {
  const { storeId } = useParams()
  const navigate = useNavigate()
  const [totalPages, setTotalPages] = useState<number>()
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [advertisementsList, setAdvertisementsList] =
    useState<IAdvertisement[]>()
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [deleteModalAdvertisementInfo, setDeleteModalAdvertisementInfo] =
    useState<IAdvertisement>()
  const PAGE_SIZE = 10

  const loadAdvertisements = async (page: number = 1) => {
    const ads: IAdvertisement[] = (
      await advertisementService.getByStore(+storeId!)
    ).data
    const skip = (page - 1) * PAGE_SIZE
    const take = skip + PAGE_SIZE

    if (!ads) return

    setAdvertisementsList(ads.slice(skip, take))
    setTotalPages(Math.ceil(ads.length / PAGE_SIZE))
  }

  useEffect(() => {
    loadAdvertisements(currentPage)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage])

  return (
    <>
      <Header />
      <div className="bg-gray">
        <div className="ads-container container-fluid p-4">
          <div className="d-flex flex-row align-items-center justify-content-between w-100 mt-1 mb-4">
            <h2>Todos Anúncios</h2>
            <Button
              variant="primary"
              onClick={() => navigate(`/create-advert/${storeId}`)}
            >
              Novo Anúncio
            </Button>
          </div>
          {!advertisementsList || advertisementsList.length <= 0 ? (
            <h3>Nenhum anúncio encontrado</h3>
          ) : (
            <>
              <Table striped borderless hover>
                <thead>
                  <tr>
                    <th>Nome do anúncio</th>
                    <th>Data de veiculação</th>
                    <th>Descrição</th>
                    <th>Tipo</th>
                  </tr>
                </thead>
                <tbody>
                  {!advertisementsList
                    ? ""
                    : advertisementsList.map((advert) => (
                        <tr key={advert.id}>
                          <td>{advert.title}</td>
                          <td>
                            {new Date(advert.startDate).toLocaleDateString()}
                            {" até "}
                            {new Date(advert.endDate).toLocaleDateString()}
                          </td>
                          <td>{advert.description}</td>
                          <td>{advert.type}</td>
                          <td>
                            <Dropdown>
                              <Dropdown.Toggle
                                variant="secondary"
                                id="dropdown-basic"
                                as="span"
                                className="p-0 border-0 bg-transparent"
                              >
                                <FaEllipsisV />
                              </Dropdown.Toggle>

                              <Dropdown.Menu className="table-dropdown-menu">
                                <Dropdown.Item
                                  onClick={() => {
                                    navigate(`/edit-advert/${advert.id}`)
                                  }}
                                >
                                  Editar
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => {
                                    setDeleteModalAdvertisementInfo(advert)
                                    setShowDeleteModal(true)
                                  }}
                                >
                                  Excluir
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => {
                                    navigate(`/advert-metrics/${advert.id}`)
                                  }}
                                >
                                  Visualizar métricas
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      ))}
                </tbody>
              </Table>
              <Pagination className="mt-3 gap-2 align-items-center">
                <Pagination.Prev
                  onClick={() => {
                    setCurrentPage(Math.max(currentPage - 1, 1))
                  }}
                >
                  <FaChevronLeft />
                </Pagination.Prev>
                {[...new Array(totalPages)].map((_arr, page) => (
                  <Pagination.Item
                    key={page}
                    active={page + 1 === currentPage}
                    onClick={() => {
                      if (page + 1 !== currentPage) {
                        setCurrentPage(page + 1)
                      }
                    }}
                  >
                    {page + 1}
                  </Pagination.Item>
                ))}
                <Pagination.Next
                  onClick={() => {
                    setCurrentPage(Math.min(currentPage + 1, totalPages!))
                  }}
                >
                  <FaChevronRight />
                </Pagination.Next>
              </Pagination>
            </>
          )}
        </div>
      </div>
      <Modal
        centered
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
      >
        <Modal.Header>
          <div className="modal-icon border rounded-circle">
            <PiWarning size={24} />
          </div>
          <Modal.Title className="fw-bold">Remover anúncio</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Deseja remover o anúncio "
          <span className="fw-bold">{deleteModalAdvertisementInfo?.title}</span>
          "?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={() => setShowDeleteModal(false)}>
            CANCELAR
          </Button>
          <Button
            variant="danger"
            onClick={async () => {
              try {
                await advertisementService.deactivate(
                  deleteModalAdvertisementInfo!.id
                )
                setShowDeleteModal(false)
                loadAdvertisements(currentPage)
                swal("Concluído!", "Anúncio removido com sucesso.", "success")
              } catch {
                swal(
                  "Erro!",
                  "Ocorreu um erro ao remover o anúncio. Tente de novo.",
                  "error"
                )
              }
            }}
          >
            SIM, REMOVER
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default AdvertisementsList
