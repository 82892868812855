import React, { createContext, useEffect, useState } from "react"
import { IAuthContextData } from "../shared/interfaces/IAuthContextData"
import { IProviderProps } from "../shared/interfaces/IProviderProps"
import { IUser } from "../shared/interfaces/IUser"

const AuthContext = createContext<IAuthContextData>({} as IAuthContextData)

export const AuthProvider: React.FC<IProviderProps> = ({
  children,
}: React.PropsWithChildren<{}>) => {
  const [signed, setSigned] = useState(!!localStorage.getItem("hash"))
  const [user, setUser] = useState<Partial<IUser> | undefined>()

  useEffect(() => {
    const storedUser = JSON.parse(window.sessionStorage.getItem("user") || "{}")
    setUser(storedUser)
  }, [])

  function markAsSigned() {
    setSigned(true)
  }

  function markAsNotSigned() {
    setSigned(false)
  }

  function selectUser(user: Partial<IUser>) {
    window.sessionStorage.setItem("user", JSON.stringify(user))

    setUser(user)
  }

  function getUser() {
    if (user) {
      return user
    } else {
      const storedUser = JSON.parse(
        window.sessionStorage.getItem("user") || "{}"
      )
      return storedUser
    }
  }

  return (
    <AuthContext.Provider
      value={{
        signed,
        markAsSigned,
        markAsNotSigned,
        getUser,
        selectUser,
        user,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContext
