import { Status } from "../shared/enums/Status.enum"
import { UserDTO } from "../dtos/user.dto"
import api from "./api"

class UserService {
  async deactivateManager(id: number) {
    return api
      .patch(`/users/disable/${id}`, {
        name: "anônimo",
        phone: "anônimo",
        email: `anônimo${id}@anônimo.com.br`,
        password: "",
        picture: null,
        status: Status.NO,
        roles: 0,
      })
      .then((result) => Promise.resolve(result))
      .catch((error) => Promise.reject(error))
  }

  async deactivateShopkeeper(id: number) {
    return api
      .patch(`/users/disable/${id}`, {
        name: "anônimo",
        phone: "anônimo",
        email: `anônimo${id}@anônimo.com.br`,
        password: "",
        picture: null,
        status: Status.NO,
      })
      .then((result) => Promise.resolve(result))
      .catch((error) => Promise.reject(error))
  }

  async getUsers() {
    return api
      .get("/users")
      .then((result) => {
        return Promise.resolve(result.data)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  async create(user: UserDTO) {
    return api
      .post("/users", user)
      .then((result) => {
        return Promise.resolve(result.data)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }
}

const userService = new UserService()
export default userService
