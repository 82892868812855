import api from "./api"
import { CreateManagerDto } from "../dtos/manager.dto"
import { UserDTO } from "../dtos/user.dto"

class ManagerService {
  async getManagers() {
    return api
      .get("/managers")
      .then((result) => {
        return Promise.resolve(result.data)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  async getById(id: number) {
    return api
      .get(`/managers/${id}`)
      .then((result) => {
        return Promise.resolve(result.data)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  async getOneByUser(userId: number) {
    return api
      .get(`/managers/user/${userId}`)
      .then((result) => {
        return Promise.resolve(result.data)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  async getAllByUser(userId: number) {
    return api
      .get(`/managers/all/user/${userId}`)
      .then((result) => {
        return Promise.resolve(result.data)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  async getAllById(id: number) {
    return api
      .get(`/managers/all/${id}`)
      .then((result) => {
        return Promise.resolve(result.data)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  async create(manager: CreateManagerDto) {
    return api
      .post("/managers", manager)
      .then((result) => {
        return Promise.resolve(result.data)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  async updateUser(id: number, userData: Partial<UserDTO>) {
    return api
      .patch(`/managers/${id}`, { user: userData })
      .then((result) => Promise.resolve(result))
      .catch((error) => Promise.reject(error))
  }
}

const managerService = new ManagerService()
export default managerService
