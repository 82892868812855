import React, { useState } from "react"
import {
  Modal,
  ModalHeader,
  ModalTitle,
  ModalBody,
  ModalFooter,
} from "react-bootstrap"
import { UserDTO } from "../../dtos/user.dto"
import userService from "../../services/UserService"
import Joi from "joi"
import swal from "sweetalert"
import { UserRoles } from "../../shared/enums/UserRoles.enum"

interface ModalResponsibleRegisterProps {
  show: boolean
  onClose: () => void
}

interface Errors {
  modalResponsibleName?: string
  modalResponsibleEmail?: string
  modalResponsiblePassword?: string
  modalResponsibleConfirmPassword?: string
  modalResponsiblePhone?: string
  modalResponsibleStatus?: string
}

const ModalResponsibleRegister: React.FC<ModalResponsibleRegisterProps> = ({
  show,
  onClose,
}) => {
  const [modalResponsibleName, setModalResponsibleName] = useState("")
  const [modalResponsibleEmail, setModalResponsibleEmail] = useState("")
  const [modalResponsiblePassword, setModalResponsiblePassword] = useState("")
  const [modalResponsibleConfirmPassword, setModalResponsibleConfirmPassword] =
    useState("")
  const [modalResponsiblePhone, setModalResponsiblePhone] = useState("")
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [errors, setErrors] = useState<Errors>({})

  const schema = Joi.object({
    modalResponsibleName: Joi.string().required().messages({
      "string.empty": "O nome é obrigatório",
    }),
    modalResponsibleEmail: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .messages({
        "string.empty": "O email é obrigatório",
        "string.email": "O email não é válido",
      }),
    modalResponsiblePassword: Joi.string().min(6).required().messages({
      "string.empty": "A senha é obrigatória",
      "string.min": "A senha deve ter pelo menos 6 caracteres",
    }),
    modalResponsibleConfirmPassword: Joi.string()
      .valid(Joi.ref("modalResponsiblePassword"))
      .required()
      .messages({
        "string.empty": "A confirmação da senha é obrigatória",
        "any.only": "A confirmação da senha não está igual à senha digitada",
      }),
    modalResponsiblePhone: Joi.string()
      .pattern(new RegExp("^[0-9]{10,11}$"))
      .required()
      .messages({
        "string.empty": "O telefone é obrigatório",
        "string.pattern.base": "O telefone não é válido",
      }),
  })

  const validateForm = () => {
    const { error } = schema.validate(
      {
        modalResponsibleName,
        modalResponsibleEmail,
        modalResponsiblePassword,
        modalResponsibleConfirmPassword,
        modalResponsiblePhone,
      },
      { abortEarly: false }
    )

    if (error) {
      setErrors(
        error.details.reduce(
          (acc: Record<string, string>, curr: Joi.ValidationErrorItem) => {
            acc[curr.path.join(".")] = curr.message
            return acc
          },
          {}
        )
      )
      return false
    }
    setErrors({})
    return true
  }

  const handleClose = () => {
    setModalResponsibleName("")
    setModalResponsibleEmail("")
    setModalResponsiblePassword("")
    setModalResponsibleConfirmPassword("")
    setModalResponsiblePhone("")
    setErrors({})
    onClose()
  }

  const handleModalSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    const isValid = validateForm()

    if (!isValid) {
      swal(
        "Atenção!",
        "Todos os campos devem ser preenchidos corretamente.",
        "error"
      )
      return
    }

    const user: UserDTO = {
      name: modalResponsibleName,
      email: modalResponsibleEmail,
      password: modalResponsiblePassword,
      phone: modalResponsiblePhone,
      roles: UserRoles.MANAGER,
    }

    try {
      setIsLoading(true)
      await userService.create(user)

      setModalResponsibleName("")
      setModalResponsibleEmail("")
      setModalResponsiblePassword("")
      setModalResponsibleConfirmPassword("")
      setModalResponsiblePhone("")

      handleClose()
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName="modal-90w custom-modal-padding"
    >
      <ModalHeader className="border-0 pb-3">
        <ModalTitle className="mx-auto">
          CADASTRO DE RESPONSÁVEL PELO NEGÓCIO
        </ModalTitle>
      </ModalHeader>

      <hr className="blue-line" />

      <ModalBody className="border-0 pt-2 pb-3">
        <form onSubmit={handleModalSubmit}>
          <div className="mb-3">
            <label htmlFor="name" className="form-label">
              Nome completo
            </label>
            <input
              type="text"
              className="form-control"
              id="name"
              placeholder="Insira o nome do responsável"
              value={modalResponsibleName}
              onChange={(e) => setModalResponsibleName(e.target.value)}
              onInput={() =>
                setErrors((prev) => ({
                  ...prev,
                  modalResponsibleName: undefined,
                }))
              }
            />
            {errors.modalResponsibleName && (
              <p style={{ color: "darkred" }}>*{errors.modalResponsibleName}</p>
            )}
          </div>

          <div className="mb-3">
            <label htmlFor="email" className="form-label">
              E-mail
            </label>
            <input
              type="email"
              className="form-control"
              id="email"
              placeholder="Insira o email do responsável"
              value={modalResponsibleEmail}
              onChange={(e) => setModalResponsibleEmail(e.target.value)}
              onInput={() =>
                setErrors((prev) => ({
                  ...prev,
                  modalResponsibleEmail: undefined,
                }))
              }
            />
            {errors.modalResponsibleEmail && (
              <p style={{ color: "darkred" }}>
                *{errors.modalResponsibleEmail}
              </p>
            )}
          </div>

          <div className="mb-3">
            <label htmlFor="password" className="form-label">
              Senha
            </label>
            <input
              type="password"
              className="form-control"
              id="password"
              placeholder="Insira a senha"
              value={modalResponsiblePassword}
              onChange={(e) => setModalResponsiblePassword(e.target.value)}
              onInput={() =>
                setErrors((prev) => ({
                  ...prev,
                  modalResponsiblePassword: undefined,
                }))
              }
            />
            {errors.modalResponsiblePassword && (
              <p style={{ color: "darkred" }}>
                *{errors.modalResponsiblePassword}
              </p>
            )}
          </div>

          <div className="mb-3">
            <label htmlFor="confirmPassword" className="form-label">
              Digite a senha de novo
            </label>
            <input
              type="password"
              className="form-control"
              id="confirmPassword"
              placeholder="Digite a senha de novo"
              value={modalResponsibleConfirmPassword}
              onChange={(e) =>
                setModalResponsibleConfirmPassword(e.target.value)
              }
              onInput={() =>
                setErrors((prev) => ({
                  ...prev,
                  modalResponsibleConfirmPassword: undefined,
                }))
              }
            />
            {errors.modalResponsibleConfirmPassword && (
              <p style={{ color: "darkred" }}>
                *{errors.modalResponsibleConfirmPassword}
              </p>
            )}
          </div>

          <div className="mb-3">
            <label htmlFor="phone" className="form-label">
              Fone
            </label>
            <input
              type="tel"
              className="form-control"
              id="phone"
              placeholder="Insira o número de telefone"
              value={modalResponsiblePhone}
              onChange={(e) => setModalResponsiblePhone(e.target.value)}
              onInput={() =>
                setErrors((prev) => ({
                  ...prev,
                  modalResponsiblePhone: undefined,
                }))
              }
            />
            {errors.modalResponsiblePhone && (
              <p style={{ color: "darkred" }}>
                *{errors.modalResponsiblePhone}
              </p>
            )}
          </div>

          <ModalFooter className="border-0">
            <div className="w-100 d-flex justify-content-center">
              <div className="d-flex justify-content-between w-50">
                <button
                  type="button"
                  className="btn btn-secondary flex-grow-1"
                  onClick={handleClose}
                  style={{ marginRight: "20px" }}
                >
                  CANCELAR
                </button>
                <button type="submit" className="btn btn-primary flex-grow-1">
                  CADASTRAR
                </button>
              </div>
            </div>
          </ModalFooter>
        </form>
      </ModalBody>
    </Modal>
  )
}

export default ModalResponsibleRegister
