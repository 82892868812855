import "./App.css"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import { AuthProvider } from "./context/AuthContext"
import Login from "./pages/Login"
import LocationsList from "./pages/LocationsList"
import ManagersList from "./pages/ManagersList"
import CreateLocation from "./pages/Location/CreateLocation"
import PrivateRoute from "./components/PrivateRoute/PrivateRoute"
import { UserRoles } from "./shared/enums/UserRoles.enum"
import RegisterResponsible from "./pages/RegisterResponsible/RegisterResponsible"
import EditManager from "./pages/EditManager"
import EditLocation from "./pages/EditLocation"
import ShopkeepersList from "./pages/ShopkeepersList"
import RegisterShopkeeper from "./pages/RegisterShopkeeper/RegisterShopkeeper"
import EditShopkeeper from "./pages/EditShopkeeper"
import AdvertisementsList from "./pages/AdvertisementsList"
import RegisterAdvertisement from "./pages/RegisterAdvertisement/RegisterAdvertisement"
import EditAdvertisement from "./pages/EditAdvert/EditAdvertisement"
import AdvertisementsMetrics from "./pages/AdvertisementsMetrics"
import CreateStore from "./pages/CreateStore/CreateStore"
import EditStore from "./pages/EditStore/EditStore"
import ListStore from "./pages/ListStore/ListStore"
import MyStore from "./pages/MyStore/MyStore"
import MapList from "./pages/MapList/MapList"

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="/locations"
            element={
              <PrivateRoute
                role={UserRoles.ADMIN}
                element={<LocationsList />}
              />
            }
          />
          <Route
            path="/managers/:locationId"
            element={
              <PrivateRoute role={UserRoles.ADMIN} element={<ManagersList />} />
            }
          />
          <Route
            path="/create-manager/:locationId"
            element={
              <PrivateRoute
                role={UserRoles.ADMIN}
                element={<RegisterResponsible />}
              />
            }
          />
          <Route
            path="/create-location"
            element={
              <PrivateRoute
                role={UserRoles.ADMIN}
                element={<CreateLocation />}
              />
            }
          />
          <Route
            path="/edit-manager/:managerId"
            element={
              <PrivateRoute role={UserRoles.ADMIN} element={<EditManager />} />
            }
          />
          <Route
            path="/edit-location/:locationId"
            element={
              <PrivateRoute role={UserRoles.ADMIN} element={<EditLocation />} />
            }
          />
          <Route
            path="/create-store/:locationId"
            element={
              <PrivateRoute
                role={UserRoles.ADMIN | UserRoles.MANAGER}
                element={<CreateStore />}
              />
            }
          />
          <Route
            path="/edit-store/:storeId"
            element={
              <PrivateRoute
                role={UserRoles.ADMIN | UserRoles.MANAGER}
                element={<EditStore />}
              />
            }
          />
          <Route
            path="/list-stores/:locationId"
            element={
              <PrivateRoute
                role={UserRoles.ADMIN | UserRoles.MANAGER}
                element={<ListStore />}
              />
            }
          />
          <Route
            path="/list-shopkeepers"
            element={
              <PrivateRoute
                role={UserRoles.ADMIN | UserRoles.MANAGER}
                element={<ShopkeepersList />}
              />
            }
          />
          <Route
            path="/shopkeepers-list/:storeId"
            element={
              <PrivateRoute
                role={UserRoles.ADMIN | UserRoles.MANAGER}
                element={<ShopkeepersList />}
              />
            }
          />
          <Route
            path="/create-shopkeeper/:storeId"
            element={
              <PrivateRoute
                role={UserRoles.ADMIN | UserRoles.MANAGER}
                element={<RegisterShopkeeper />}
              />
            }
          />
          <Route
            path="/edit-shopkeeper/:shopkeeperId"
            element={
              <PrivateRoute
                role={UserRoles.ADMIN | UserRoles.MANAGER}
                element={<EditShopkeeper />}
              />
            }
          />
          <Route
            path="/advertisements/:storeId"
            element={
              <PrivateRoute
                role={UserRoles.ADMIN | UserRoles.SHOPKEEPER}
                element={<AdvertisementsList />}
              />
            }
          />
          <Route
            path="/create-advert/:storeId"
            element={
              <PrivateRoute
                role={UserRoles.ADMIN | UserRoles.SHOPKEEPER}
                element={<RegisterAdvertisement />}
              />
            }
          />
          <Route
            path="/edit-advert/:advertId"
            element={
              <PrivateRoute
                role={UserRoles.ADMIN | UserRoles.SHOPKEEPER}
                element={<EditAdvertisement />}
              />
            }
          />
          <Route
            path="/advert-metrics/:advertId"
            element={
              <PrivateRoute
                role={UserRoles.ADMIN | UserRoles.SHOPKEEPER}
                element={<AdvertisementsMetrics />}
              />
            }
          />
          <Route
            path="/my-store"
            element={
              <PrivateRoute
                role={UserRoles.ADMIN | UserRoles.SHOPKEEPER}
                element={<MyStore />}
              />
            }
          />
          <Route
            path="/map-list"
            element={
              <PrivateRoute
                role={UserRoles.ADMIN | UserRoles.SHOPKEEPER}
                element={<MapList />}
              />
            }
          />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  )
}

export default App
