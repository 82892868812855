import api from "./api"
import { UserDTO } from "../dtos/user.dto"
import { IShopkeeper } from "../shared/interfaces/IShopkeeper"

class ShopkeeperService {
  async getManagers() {
    return api
      .get("/shopkeepers")
      .then((result) => {
        return Promise.resolve(result.data)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  async getById(id: number) {
    return api
      .get(`/shopkeepers/${id}`)
      .then((result) => {
        return Promise.resolve(result.data)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  async getByUser(userId: number) {
    return api
      .get(`/shopkeepers/user/${userId}`)
      .then((result) => {
        return Promise.resolve(result.data)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  async getAllByUser(userId: number) {
    return api
      .get(`/shopkeepers/all/user/${userId}`)
      .then((result) => {
        return Promise.resolve(result.data)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  async create(shopkeeper: Partial<IShopkeeper>) {
    return api
      .post("/shopkeepers", shopkeeper)
      .then((result) => {
        return Promise.resolve(result.data)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  async updateUser(id: number, userData: Partial<UserDTO>) {
    return api
      .patch(`/shopkeepers/${id}`, { user: userData })
      .then((result) => Promise.resolve(result))
      .catch((error) => Promise.reject(error))
  }
}

const shopkeeperService = new ShopkeeperService()
export default shopkeeperService
