import React, { useContext, useEffect, useState } from "react"
import Header from "../../components/Header/Header"
import { UpdateStoreDTO } from "../../dtos/store.dto"
import storeService from "../../services/StoreService"
import swal from "sweetalert"
import Joi from "joi"
import "./MyStore.scss"
import { FaCloudUploadAlt, FaChevronLeft, FaChevronRight } from "react-icons/fa"
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner"
import shopkeeperService from "../../services/ShopkeeperService"
import AuthContext from "../../context/AuthContext"

interface Errors {
  storeName?: string
  storeEmail?: string
  storePhone?: string
  storeStartAt?: string
  storeDescription?: string
  storeLogo?: string
  storeBanner?: string
}

const MyStore = () => {
  const { getUser } = useContext(AuthContext)
  const user = getUser()
  const [showStoresSlider, setShowStoresSlider] = useState<boolean>(false)
  const [nextStoreId, setNextStoreId] = useState<number>()
  const [prevStoreId, setPrevStoreId] = useState<number>()
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [storeName, setStoreName] = useState("")
  const [storeEmail, setStoreEmail] = useState('')
  const [storePhone, setStorePhone] = useState("")
  const [storeStartedAt, setStoreStartedAt] = useState("")
  const [storeDescription, setStoreDescription] = useState("")
  const [storeLogo, setStoreLogo] = useState<File | null>(null)
  const [storeBanner, setStoreBanner] = useState<File | null>(null)
  const [logoUrl, setLogoUrl] = useState<string | null>(null)
  const [bannerUrl, setBannerUrl] = useState<string | null>(null)
  const [errors, setErrors] = useState<Errors>({})
  const [imageData, setImageData] = useState<ImageData | null>(null)
  const [selectedLogoName, setSelectedLogoName] = useState("")
  const [selectedBannerName, setSelectedBannerName] = useState("")
  const storeId = window.sessionStorage.getItem("current-store")
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const getStore = async () => {
    if (!storeId) {
      console.error("storeId is undefined or null")
      return
    }

    try {
      const store = await storeService.getById(+storeId)
      if (!store.data) {
        console.error("No store data found")
        return
      }

      const updateStoreDTO = {
        name: store.data.name,
        email: store.data.email,
        phone: store.data.phone,
        description: store.data.description,
        startedAt: store.data.startedAt,
        banner: store.data.banner,
        logo: store.data.logo,
      }

      const startedAtFormatted = new Date(updateStoreDTO.startedAt)
        .toISOString()
        .split("T")[0]

      setImageData(imageData)

      setStoreName(updateStoreDTO.name)
      setStoreEmail(updateStoreDTO.email)
      setStorePhone(updateStoreDTO.phone)
      setStoreStartedAt(startedAtFormatted)
      setStoreDescription(updateStoreDTO.description)
      setStoreLogo(null)
      setStoreBanner(null)

      setLogoUrl(
        updateStoreDTO.logo
          ? `https://nyc3.digitaloceanspaces.com/test-fatecoins-bucket/${updateStoreDTO.logo}`
          : null
      )
      setBannerUrl(
        updateStoreDTO.banner
          ? `https://nyc3.digitaloceanspaces.com/test-fatecoins-bucket/${updateStoreDTO.banner}`
          : null
      )

      setNextStoreId(
        (await storeService.getNextStoreIdByShopkeeper(+storeId!, user.id!))
          .data.store_id
      )
      setPrevStoreId(
        (await storeService.getPrevStoreIdByShopkeeper(+storeId!, user.id!))
          .data.store_id
      )
    } catch (error) {
      console.error("Failed to fetch store", error)
    }
  }

  useEffect(() => {
    shopkeeperService.getAllByUser(+user.id!).then((res) => {
      if (res.length > 1) {
        setShowStoresSlider(true)
      } else {
        setShowStoresSlider(false)
      }
    })
  }, [user.id, user.roles])

  useEffect(() => {
    getStore()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeId])

  if (isLoading) {
    return <LoadingSpinner />
  }

  const schema = Joi.object({
    storeName: Joi.string().required().messages({
      "string.empty": "O nome é obrigatório",
    }),

    storeEmail: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      "string.empty": "O email é obrigatório",
      "string.email": "O email não é válido",
    }),

    storePhone: Joi.string()
      .pattern(new RegExp("^[0-9]{10,11}$"))
      .required()
      .messages({
        "string.empty": "O telefone é obrigatório",
        "string.pattern.base": "O telefone não é válido",
      }),

    storeStartedAt: Joi.string().isoDate().required().messages({
      "string.empty": "A data de início é obrigatória",
      "string.isoDate": "A data de início deve estar no formato dd/mm/aaaa",
    }),

    storeDescription: Joi.string().min(10).max(500).required().messages({
      "string.empty": "A descrição é obrigatória",
      "string.min": "A descrição deve ter pelo menos 10 caracteres",
      "string.max": "A descrição não pode ter mais de 500 caracteres",
    }),

    storeLogo: Joi.any()
      .custom((value, helpers) => {
        if (value && !value.type.startsWith("image/")) {
          return helpers.message("O logo deve ser um arquivo de imagem" as any)
        }
        return value
      }, "image validation")
      .optional()
      .messages({
        "file.invalidType": "O logo deve ser um arquivo de imagem",
      }),

    storeBanner: Joi.any()
      .custom((value, helpers) => {
        if (value && !value.type.startsWith("image/")) {
          return helpers.message(
            "O banner deve ser um arquivo de imagem" as any
          )
        }
        return value
      }, "image validation")
      .optional()
      .messages({
        "any.invalid": "O valor fornecido não é um arquivo",
        "file.invalidType": "O banner deve ser um arquivo de imagem",
      }),
  })

  const validateForm = () => {
    const { error } = schema.validate(
      {
        storeName,
        storeEmail,
        storePhone,
        storeStartedAt,
        storeDescription,
        storeLogo,
        storeBanner,
      },
      { abortEarly: false }
    )

    if (error) {
      setErrors(
        error.details.reduce(
          (acc: Record<string, string>, curr: Joi.ValidationErrorItem) => {
            acc[curr.path[0]] = curr.message
            return acc
          },
          {}
        )
      )
      return false
    }
    setErrors({})
    return true
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    const isValid = validateForm()

    if (!isValid) {
      swal(
        "Atenção!",
        "Todos os campos devem ser preenchidos corretamente.",
        "error"
      )
      return
    }

    setIsLoading(true)

    const store: UpdateStoreDTO = {
      name: storeName,
      email: storeEmail,
      phone: storePhone,
      startedAt: storeStartedAt,
      description: storeDescription,
      logo: storeLogo,
      banner: storeBanner,
    }

    try {
      await storeService.update(store, Number(storeId))
      setIsLoading(false)
      setStoreName("")
      setStoreEmail("")
      setStorePhone("")
      setStoreStartedAt("")
      setStoreDescription("")
      setIsEditing(false)
      swal("Concluído!", "Loja atualizada com sucesso.", "success").then(() => {
        window.location.reload()
      })
    } catch (error) {
      console.error(error)
      swal(
        "Erro!",
        "Ocorreu um erro ao atualizar a loja. Tente de novo.",
        "error"
      )
    }
  }

  const handleCancel = () => {
    setIsEditing(false)
    getStore()
  }

  const handleChangeStore = (dir: "prev" | "next") => {
    window.sessionStorage.setItem(
      "current-store",
      dir === "prev" ? prevStoreId!.toString() : nextStoreId!.toString()
    )
    window.location.reload()
  }

  return (
    <>
      <Header />
      <div className="bg-gray">
        {showStoresSlider && (
          <div className="location-nav">
            <p className="mb-2 fw-bold">Navegue pelos negócios</p>
            <div className="d-flex flex-row bg-white border rounded p-2 gap-4 mb-5">
              <p
                onClick={() => handleChangeStore("prev")}
                style={{ cursor: "pointer" }}
              >
                <FaChevronLeft />
              </p>
              <h4 className="text-primary">{storeName}</h4>
              <p
                onClick={() => handleChangeStore("next")}
                style={{ cursor: "pointer" }}
              >
                <FaChevronRight />
              </p>
            </div>
          </div>
        )}
        <div className="container bg-white form-container edit-store-style">
          <div className="container-fluid edit-store-container">
            <form onSubmit={handleSubmit}>
              <div className="d-flex justify-content-between">
                <div className="mb-3 flex-grow-1 me-2">
                  <label htmlFor="name" className="form-label">
                    Nome da loja
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder="Insira o nome da loja"
                    value={storeName}
                    disabled={!isEditing}
                    onChange={(e) => setStoreName(e.target.value)}
                    onInput={() =>
                      setErrors((prev) => ({
                        ...prev,
                        storeName: undefined,
                      }))
                    }
                  />
                  {errors.storeName && (
                    <p style={{ color: "darkred" }}>*{errors.storeName}</p>
                  )}
                </div>

                <div className="mb-3 flex-grow-1 mx-2">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="Insira o email"
                    value={storeEmail}
                    onChange={(e) => setStoreEmail(e.target.value)}
                    onInput={() =>
                      setErrors((prev) => ({
                        ...prev,
                        storeEmail: undefined,
                      }))
                    }
                  />
                  {errors.storeEmail && (
                    <p style={{ color: "darkred" }}>*{errors.storeEmail}</p>
                  )}
                </div>

                <div className="mb-3 flex-grow-1 mx-2">
                  <label htmlFor="phone" className="form-label">
                    Fone
                  </label>
                  <input
                    type="tel"
                    className="form-control"
                    id="phone"
                    placeholder="Insira o número de telefone"
                    value={storePhone}
                    disabled={!isEditing}
                    onChange={(e) => setStorePhone(e.target.value)}
                    onInput={() =>
                      setErrors((prev) => ({
                        ...prev,
                        storePhone: undefined,
                      }))
                    }
                  />
                  {errors.storePhone && (
                    <p style={{ color: "darkred" }}>*{errors.storePhone}</p>
                  )}
                </div>

                <div className="mb-3 flex-grow-1 ms-2">
                  <label htmlFor="started-at" className="form-label">
                    Data de início
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    id="started-at"
                    placeholder="Insira a data de início"
                    value={storeStartedAt}
                    disabled={!isEditing}
                    onChange={(e) => setStoreStartedAt(e.target.value)}
                    onInput={() =>
                      setErrors((prev) => ({
                        ...prev,
                        storeStartAt: undefined,
                      }))
                    }
                  />
                  {errors.storeStartAt && (
                    <p style={{ color: "darkred" }}>*{errors.storeStartAt}</p>
                  )}
                </div>
              </div>

              <div className="mb-3 flex-grow-1 mx-2">
                <label htmlFor="description" className="form-label">
                  Descrição da Loja
                </label>
                <textarea
                  className="form-control"
                  id="description"
                  placeholder="Insira a descrição da loja"
                  value={storeDescription}
                  rows={3}
                  disabled={!isEditing}
                  onChange={(e) => setStoreDescription(e.target.value)}
                  onInput={() =>
                    setErrors((prev) => ({
                      ...prev,
                      storeDescription: undefined,
                    }))
                  }
                />
                {errors.storeDescription && (
                  <p style={{ color: "darkred" }}>*{errors.storeDescription}</p>
                )}
              </div>

              <div className="image-div">
                <span>Logo da loja</span>
                <div>
                  <img
                    src={logoUrl!}
                    alt="logo da loja"
                    className="image-appearances"
                  />
                </div>
                {isEditing && (
                  <div className="mb-3">
                    <input
                      type="file"
                      className="form-control"
                      id="logo"
                      accept="image/*"
                      style={{ display: "none" }}
                      onChange={(e) => {
                        if (e.target.files && e.target.files[0]) {
                          setStoreLogo(e.target.files[0])
                          setSelectedLogoName(e.target.files[0].name)
                          setErrors((prev) => ({
                            ...prev,
                            storeLogo: undefined,
                          }))
                        }
                      }}
                    />
                    <label htmlFor="logo" className="uploadButton">
                      <FaCloudUploadAlt style={{ fontSize: "16px" }} />
                      Escolher outro logo
                    </label>
                    {selectedLogoName && (
                      <span style={{ marginLeft: "10px" }}>
                        {selectedLogoName}
                      </span>
                    )}
                    {errors.storeLogo && (
                      <p style={{ color: "darkred" }}>*{errors.storeLogo}</p>
                    )}
                  </div>
                )}
              </div>

              <hr />

              <div className="image-div">
                <span>Banner da loja</span>
                <div>
                  <img
                    src={bannerUrl!}
                    alt="banner da loja"
                    className="image-appearances"
                  />
                </div>
                {isEditing && (
                  <div className="mb-3">
                    <input
                      type="file"
                      className="form-control"
                      id="banner"
                      accept="image/*"
                      style={{ display: "none" }}
                      onChange={(e) => {
                        if (e.target.files && e.target.files[0]) {
                          setStoreBanner(e.target.files[0])
                          setSelectedBannerName(e.target.files[0].name)
                          setErrors((prev) => ({
                            ...prev,
                            storeBanner: undefined,
                          }))
                        }
                      }}
                    />
                    <label htmlFor="banner" className="uploadButton">
                      <FaCloudUploadAlt style={{ fontSize: "16px" }} />
                      Escolher outro banner
                    </label>
                    {selectedBannerName && (
                      <span style={{ marginLeft: "10px" }}>
                        {selectedBannerName}
                      </span>
                    )}
                    {errors.storeBanner && (
                      <p style={{ color: "darkred" }}>*{errors.storeBanner}</p>
                    )}
                  </div>
                )}
              </div>

              <div className="mb-3 flex-center">
                {!isEditing ? (
                  <button
                    type="button"
                    className="btn btn-primary"
                    style={{ marginRight: "30px" }}
                    onClick={() => setIsEditing(true)}
                  >
                    EDITAR INFORMAÇÕES
                  </button>
                ) : (
                  <>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      style={{ marginRight: "30px" }}
                      onClick={handleCancel}
                    >
                      CANCELAR
                    </button>
                    <button type="submit" className="btn btn-primary">
                      CONFIRMAR
                    </button>
                  </>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default MyStore
