import { CreateStoreDTO, UpdateStoreDTO } from "../dtos/store.dto"
import api from "./api"

class StoreService {
  async create(newStore: CreateStoreDTO, locationId: number) {
    return api({
      url: process.env.REACT_APP_API_URL + `stores/${locationId}`,
      method: "POST",
      data: newStore,
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("hash")}`,
      },
    })
      .then((result) => {
        return Promise.resolve(result.data)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  async getAll(page?: number, limit?: number) {
    return api
      .get(`/stores?page=${page}&limit=${limit}`)
      .then((result) => Promise.resolve(result))
      .catch((error) => Promise.reject(error))
  }

  async getById(id: number) {
    return api
      .get(`/stores/${id}`)
      .then((result) => Promise.resolve(result))
      .catch((error) => Promise.reject(error))
  }

  async getNextStoreId(id: number) {
    return api
      .get(`/stores/next/${id}`)
      .then((result) => Promise.resolve(result))
      .catch((error) => Promise.reject(error))
  }

  async getPrevStoreId(id: number) {
    return api
      .get(`/stores/prev/${id}`)
      .then((result) => Promise.resolve(result))
      .catch((error) => Promise.reject(error))
  }

  async getNextStoreIdByShopkeeper(id: number, shopkeeperId: number) {
    return api
      .get(`/stores/next/${id}/${shopkeeperId}`)
      .then((result) => Promise.resolve(result))
      .catch((error) => Promise.reject(error))
  }

  async getPrevStoreIdByShopkeeper(id: number, shopkeeperId: number) {
    return api
      .get(`/stores/prev/${id}/${shopkeeperId}`)
      .then((result) => Promise.resolve(result))
      .catch((error) => Promise.reject(error))
  }

  async update(body: UpdateStoreDTO, storeId: number) {
    return api({
      url: process.env.REACT_APP_API_URL + `stores/${storeId}`,
      method: "PATCH",
      data: body,
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("hash")}`,
      },
    })
      .then((response) => {
        return Promise.resolve(response)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  async delete(storeId: number) {
    return api({
      url: process.env.REACT_APP_API_URL + `stores/${storeId}`,
      method: "DELETE",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("hash")}`,
      },
    })
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  }
}

const storeService = new StoreService()
export default storeService
