import { useEffect, useState } from "react"
import { IStore } from "../../shared/interfaces/IStore"
import locationService from "../../services/LocationService"

interface StorePickerProps {
  locationId: number
  defaultValue?: IStore
  onChange: (value: IStore) => void
  id: string
  className?: string
}

export function StorePicker({
  locationId,
  defaultValue,
  onChange,
  id,
}: StorePickerProps) {
  const [stores, setStores] = useState<IStore[]>([])
  const [searchText, setSearchText] = useState(defaultValue?.name || "")
  const [suggestionsVisible, setSuggestionsVisible] = useState(false)

  useEffect(() => {
    locationService.getById(+locationId!).then((res) => {
      setStores(res.data.stores)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (!document.getElementById(id)?.contains(e.target as Node)) {
        setSuggestionsVisible(false)
      }
    }

    document.addEventListener("click", handleClickOutside)
    return () => {
      document.removeEventListener("click", handleClickOutside)
    }
  }, [id])

  return (
    <div className="w-100 position-relative">
      <div className="d-flex align-items-center">
        <input
          id={id}
          className="form-control"
          value={searchText}
          placeholder="Selecione a loja"
          onChange={(e) => setSearchText(e.target.value)}
          onFocus={() => setSuggestionsVisible(true)}
          type="text"
          autoComplete="off"
        />
      </div>
      {suggestionsVisible && (
        <div className="position-absolute zindex-tooltip w-100">
          <div className="d-flex flex-column gap-1 overflow-auto text-center mt-1 border border-gray bg-white p-1 rounded w-100">
            {stores.map((store, idx) => {
              return (
                (store.name
                  .trim()
                  .toLowerCase()
                  .includes(searchText.trim().toLowerCase()) ||
                  searchText.trim() === "") && (
                  <button
                    key={idx}
                    onClick={() => {
                      onChange(store)
                      setSearchText(store.name)
                      setSuggestionsVisible(false)
                    }}
                    className="btn btn-primary w-100"
                  >
                    <p className="text-center fw-semibold fs-6 m-0">
                      {store.name}
                    </p>
                  </button>
                )
              )
            })}
          </div>
        </div>
      )}
    </div>
  )
}
