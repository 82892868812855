import { useEffect, useState } from "react"
import Header from "../../components/Header/Header"
import { useParams } from "react-router-dom"
import advertisementService from "../../services/AdvertisementService"
import "./AdvertisementsMetrics.scss"
import { IAdvertisement } from "../../shared/interfaces/IAdvertisement"
import { Image } from "react-bootstrap"

const AdvertisementsMetrics = () => {
  const { advertId } = useParams()
  const [startDate, setStartDate] = useState<string>()
  const [endDate, setEndDate] = useState<string>()
  const [title, setTitle] = useState<string>("")
  const [description, setDescription] = useState<string>()
  const [bannerImg, setBannerImg] = useState<string>()

  const getAdvert = async () => {
    let advert: IAdvertisement = (
      await advertisementService.getById(+advertId!)
    ).data

    setTitle(advert.title)
    setStartDate(advert.startDate.toString())
    setEndDate(advert.endDate.toString())
    setBannerImg(advert.bannerImage)
    setDescription(advert.description)
  }

  useEffect(() => {
    getAdvert()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [advertId])

  return (
    <>
      <Header />
      <div className="bg-gray">
        <div
          className="container bg-white"
          style={{ width: "60%", margin: "0 auto" }}
        >
          <div
            className="container-fluid"
            style={{ maxWidth: "860px", paddingTop: "10px" }}
          >
            <h3 className="mb-4">{title}</h3>
            <div className="mb-3 d-flex flex-row gap-5">
              <div className="datesContainer">
                <label htmlFor="startDate" className="form-label">
                  Data inicial
                </label>
                <input
                  type="date"
                  id="startDate"
                  className="form-control"
                  defaultValue={startDate?.slice(0, 10)}
                  disabled
                />
              </div>
              <div className="datesContainer">
                <label htmlFor="endDate" className="form-label">
                  Data final
                </label>
                <input
                  type="date"
                  id="endDate"
                  className="form-control"
                  defaultValue={endDate?.slice(0, 10)}
                  disabled
                />
              </div>
            </div>

            <div className="mb-4">
              <label htmlFor="description" className="form-label">
                Descrição da campanha
              </label>
              <input
                type="text"
                id="description"
                className="form-control"
                defaultValue={description}
                disabled
              />
            </div>

            <div className="mb-3 d-flex flex-row gap-5">
              {bannerImg && (
                <Image
                  src={`https://nyc3.digitaloceanspaces.com/test-fatecoins-bucket/${bannerImg}`}
                  width={250}
                  height={250}
                  className="rounded"
                  style={{ border: "1px solid #e5e7eb" }}
                />
              )}
              <div className="d-flex flex-column justify-content-center align-items-center flex-wrap gap-3 w-100">
                <div className="d-flex justify-content-start w-100">
                  <h5>Métricas do anúncio</h5>
                </div>
                <div className="d-flex justify-content-between w-100">
                  <div
                    className="d-flex flex-column justify-content-center align-items-center rounded py-2 px-4"
                    style={{
                      border: "1px solid #e5e7eb",
                      backgroundColor: "#f7f8fa",
                    }}
                  >
                    <p>Clicaram</p>
                    <strong>350</strong>
                  </div>
                  <div
                    className="d-flex flex-column justify-content-center align-items-center rounded py-2 px-4"
                    style={{
                      border: "1px solid #e5e7eb",
                      backgroundColor: "#f7f8fa",
                    }}
                  >
                    <p>Não clicaram</p>
                    <strong>350</strong>
                  </div>
                  <div
                    className="d-flex flex-column justify-content-center align-items-center rounded py-2 px-4"
                    style={{
                      border: "1px solid #e5e7eb",
                      backgroundColor: "#f7f8fa",
                    }}
                  >
                    <p>Passaram em frente</p>
                    <strong>350</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AdvertisementsMetrics
