import { Button, Dropdown, Pagination, Table } from "react-bootstrap"
import Header from "../../components/Header/Header"
import "./style.scss"
import "bootstrap/dist/css/bootstrap.min.css"
import {
  FaEllipsisV,
  FaCheckCircle,
  FaTimesCircle,
  FaChevronLeft,
  FaChevronRight,
} from "react-icons/fa"
import locationService from "../../services/LocationService"
import { useEffect, useState } from "react"
import { ILocationData } from "../../shared/interfaces/ILocationData"
import { Status } from "../../shared/enums/Status.enum"
import { useNavigate } from "react-router-dom"
import swal from "sweetalert"

const LocationsList = () => {
  const navigate = useNavigate()
  const [totalPages, setTotalPages] = useState<number>()
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [locations, setLocations] = useState<ILocationData[]>()

  const loadLocations = async (page: number = 1) => {
    const { data, total } = (await locationService.getAll(page, 10)).data
    setLocations(data)
    setTotalPages(total)
  }

  useEffect(() => {
    loadLocations(currentPage)
  }, [currentPage])

  return (
    <>
      <Header />
      <div className="bg-gray">
        <div className="locations-container container-fluid p-4">
          <div className="d-flex flex-row align-items-center justify-content-between w-100 mt-1 mb-4">
            <h2>Todos os negócios</h2>
            <Button
              variant="primary"
              onClick={() => navigate("/create-location")}
            >
              Novo Negócio
            </Button>
          </div>
          {!locations ? (
            <h3>Nenhum negócio encontrado</h3>
          ) : (
            <>
              <Table striped borderless hover>
                <thead>
                  <tr>
                    <th>Nome do negócio</th>
                    <th>Responsáveis</th>
                    <th>Estado</th>
                    <th>Cidade</th>
                    <th>Endereço</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {locations.map((location) => (
                    <tr key={location.id}>
                      <td>{location.name}</td>
                      <td>
                        {location.managers.length <= 0
                          ? ""
                          : location.managers
                              .map((manager) => manager.user.name)
                              .join(", ")}
                      </td>
                      <td>{location.addressState}</td>
                      <td>{location.addressCity}</td>
                      <td>
                        {location.address}, Nº{location.addressNumber}
                      </td>
                      <td className="text-center">
                        {location.status === Status.YES ? (
                          <FaCheckCircle title="Ativo" />
                        ) : (
                          <FaTimesCircle title="Inativo" />
                        )}
                      </td>
                      <td>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="secondary"
                            id="dropdown-basic"
                            as="span"
                            className="p-0 border-0 bg-transparent"
                          >
                            <FaEllipsisV />
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="table-dropdown-menu">
                            <Dropdown.Item
                              onClick={() =>
                                navigate(`/managers/${location.id}`)
                              }
                            >
                              Responsáveis
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() =>
                                navigate(`/list-stores/${location.id}`)
                              }
                            >
                              Lojas
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => {
                                navigate(`/edit-location/${location.id}`)
                              }}
                            >
                              Editar
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={async () => {
                                try {
                                  await locationService.update(location.id!, {
                                    status:
                                      location.status === Status.YES
                                        ? Status.NO
                                        : Status.YES,
                                  })
                                  swal(
                                    "Concluído!",
                                    "Negócio atualizado com sucesso.",
                                    "success"
                                  )
                                  loadLocations(currentPage)
                                } catch {
                                  swal(
                                    "Erro!",
                                    "Ocorreu um erro ao atualizar negócio. Tente de novo.",
                                    "error"
                                  )
                                }
                              }}
                            >
                              {location.status === Status.YES
                                ? "Desativar"
                                : "Ativar"}
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <Pagination className="mt-3 gap-2 align-items-center">
                <Pagination.Prev
                  onClick={() => {
                    setCurrentPage(Math.max(currentPage - 1, 1))
                  }}
                >
                  <FaChevronLeft />
                </Pagination.Prev>
                {[...new Array(totalPages)].map((_arr, page) => (
                  <Pagination.Item
                    key={page}
                    active={page + 1 === currentPage}
                    onClick={() => {
                      if (page + 1 !== currentPage) {
                        setCurrentPage(page + 1)
                      }
                    }}
                  >
                    {page + 1}
                  </Pagination.Item>
                ))}
                <Pagination.Next
                  onClick={() => {
                    setCurrentPage(Math.min(currentPage + 1, totalPages!))
                  }}
                >
                  <FaChevronRight />
                </Pagination.Next>
              </Pagination>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default LocationsList
