import { ILocationData } from "../shared/interfaces/ILocationData"
import { CreateLocationDto } from "../dtos/location.dto"
import api from "./api"

class LocationService {
  async getAll(page?: number, limit?: number) {
    return api
      .get(`/locations?page=${page}&limit=${limit}`)
      .then((result) => Promise.resolve(result))
      .catch((error) => Promise.reject(error))
  }

  async getById(id: number) {
    return api
      .get(`/locations/${id}`)
      .then((result) => Promise.resolve(result))
      .catch((error) => Promise.reject(error))
  }

  async getNextLocationId(id: number) {
    return api
      .get(`/locations/next/${id}`)
      .then((result) => Promise.resolve(result))
      .catch((error) => Promise.reject(error))
  }

  async getPrevLocationId(id: number) {
    return api
      .get(`/locations/prev/${id}`)
      .then((result) => Promise.resolve(result))
      .catch((error) => Promise.reject(error))
  }

  async getNextLocationIdByManager(id: number, managerId: number) {
    return api
      .get(`/locations/next/${id}/${managerId}`)
      .then((result) => Promise.resolve(result))
      .catch((error) => Promise.reject(error))
  }

  async getPrevLocationIdByManager(id: number, managerId: number) {
    return api
      .get(`/locations/prev/${id}/${managerId}`)
      .then((result) => Promise.resolve(result))
      .catch((error) => Promise.reject(error))
  }

  async update(id: number, data: Partial<ILocationData>) {
    return api
      .patch(`/locations/${id}`, data)
      .then((result) => Promise.resolve(result))
      .catch((error) => Promise.reject(error))
  }

  async create(newLocation: CreateLocationDto) {
    return api
      .post("/locations", newLocation)
      .then((result) => {
        return Promise.resolve(result.data)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  async getAdressViaCep(cep: string) {
    return api
      .get(`/locations/address/${cep}`)
      .then((result) => {
        return Promise.resolve(result.data)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }
}

const locationService = new LocationService()
export default locationService
