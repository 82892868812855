import { useContext } from "react"
import { Navigate } from "react-router-dom"
import IPrivateRoute from "../../shared/interfaces/IPrivateRoute"
import AuthContext from "../../context/AuthContext"
import { hasAnyRole } from "../../lib/userRoles"

const PrivateRoute = (props: IPrivateRoute) => {
  const { getUser, signed } = useContext(AuthContext)

  if (!signed || (props.role && !hasAnyRole(getUser().roles!, props.role))) {
    return <Navigate to={"/"} />
  }
  return <div>{props.element}</div>
}

export default PrivateRoute
