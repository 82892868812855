import { Button, Dropdown, Modal, Pagination, Table } from "react-bootstrap"
import Header from "../../components/Header/Header"
import "./style.scss"
import "bootstrap/dist/css/bootstrap.min.css"
import { FaEllipsisV, FaChevronLeft, FaChevronRight } from "react-icons/fa"
import { PiWarning } from "react-icons/pi"
import { useState, useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import locationService from "../../services/LocationService"
import { ILocationData } from "../../shared/interfaces/ILocationData"
import { IManager } from "../../shared/interfaces/IManager"
import userService from "../../services/UserService"
import swal from "sweetalert"

const ManagersList = () => {
  const { locationId } = useParams()
  const navigate = useNavigate()
  const [locationName, setLocationName] = useState<string>()
  const [nextLocationId, setNextLocationId] = useState<number>()
  const [prevLocationId, setPrevLocationId] = useState<number>()
  const [totalPages, setTotalPages] = useState<number>()
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [managersList, setManagersList] = useState<IManager[]>()
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [deleteModalManagerInfo, setDeleteModalManagerInfo] =
    useState<IManager>()
  const PAGE_SIZE = 10

  const loadManagers = async (page: number = 1) => {
    const location: ILocationData = (
      await locationService.getById(+locationId!)
    ).data
    const skip = (page - 1) * PAGE_SIZE
    const take = skip + PAGE_SIZE

    if (!location) return

    setNextLocationId(
      (await locationService.getNextLocationId(location.id!)).data.location_id
    )
    setPrevLocationId(
      (await locationService.getPrevLocationId(location.id!)).data.location_id
    )
    setLocationName(location.name)
    setManagersList(location.managers.slice(skip, take))
    setTotalPages(Math.ceil(location.managers.length / PAGE_SIZE))
  }

  useEffect(() => {
    loadManagers(currentPage)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage])

  return (
    <>
      <Header />
      <div className="bg-gray">
        <div className="location-nav">
          <p className="mb-2 fw-bold">Navegue pelos negócios</p>
          <div className="d-flex flex-row bg-white border rounded p-2 gap-4 mb-5">
            <a href={`/managers/${prevLocationId}`}>
              <FaChevronLeft />
            </a>
            <h4
              className="text-primary"
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/locations")}
            >
              {locationName}
            </h4>
            <a href={`/managers/${nextLocationId}`}>
              <FaChevronRight />
            </a>
          </div>
        </div>
        <div className="locations-container container-fluid p-4">
          <div className="d-flex flex-row align-items-center justify-content-between w-100 mt-1 mb-4">
            <h2>Todos Responsáveis</h2>
            <Button
              variant="primary"
              onClick={() => navigate(`/create-manager/${locationId}`)}
            >
              Novo Responsável
            </Button>
          </div>
          {!managersList || managersList.length <= 0 ? (
            <h3>Nenhum responsável encontrado</h3>
          ) : (
            <>
              <Table striped borderless hover>
                <thead>
                  <tr>
                    <th>Nome do negócio</th>
                    <th>Responsável</th>
                    <th>E-mail</th>
                  </tr>
                </thead>
                <tbody>
                  {!managersList
                    ? ""
                    : managersList.map((manager) => (
                        <tr key={manager.id}>
                          <td>{manager.location.name}</td>
                          <td>{manager.user.name}</td>
                          <td>{manager.user.email}</td>
                          <td>
                            <Dropdown>
                              <Dropdown.Toggle
                                variant="secondary"
                                id="dropdown-basic"
                                as="span"
                                className="p-0 border-0 bg-transparent"
                              >
                                <FaEllipsisV />
                              </Dropdown.Toggle>

                              <Dropdown.Menu className="table-dropdown-menu">
                                <Dropdown.Item
                                  onClick={() => {
                                    navigate(`/edit-manager/${manager.id}`)
                                  }}
                                >
                                  Editar
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => {
                                    setDeleteModalManagerInfo(manager)
                                    setShowDeleteModal(true)
                                  }}
                                >
                                  Remover
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      ))}
                </tbody>
              </Table>
              <Pagination className="mt-3 gap-2 align-items-center">
                <Pagination.Prev
                  onClick={() => {
                    setCurrentPage(Math.max(currentPage - 1, 1))
                  }}
                >
                  <FaChevronLeft />
                </Pagination.Prev>
                {[...new Array(totalPages)].map((_arr, page) => (
                  <Pagination.Item
                    key={page}
                    active={page + 1 === currentPage}
                    onClick={() => {
                      if (page + 1 !== currentPage) {
                        setCurrentPage(page + 1)
                      }
                    }}
                  >
                    {page + 1}
                  </Pagination.Item>
                ))}
                <Pagination.Next
                  onClick={() => {
                    setCurrentPage(Math.min(currentPage + 1, totalPages!))
                  }}
                >
                  <FaChevronRight />
                </Pagination.Next>
              </Pagination>
            </>
          )}
        </div>
      </div>
      <Modal
        centered
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
      >
        <Modal.Header>
          <div className="modal-icon border rounded-circle">
            <PiWarning size={24} />
          </div>
          <Modal.Title className="fw-bold">Remover responsável</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Deseja remover o responsável "
          <span className="fw-bold">{deleteModalManagerInfo?.user.name}</span>"?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={() => setShowDeleteModal(false)}>
            CANCELAR
          </Button>
          <Button
            variant="danger"
            onClick={async () => {
              try {
                await userService.deactivateManager(
                  deleteModalManagerInfo!.user.id
                )
                setShowDeleteModal(false)
                loadManagers(currentPage)
                swal(
                  "Concluído!",
                  "Responsável removido com sucesso.",
                  "success"
                )
              } catch {
                swal(
                  "Erro!",
                  "Ocorreu um erro ao remover o responsável. Tente de novo.",
                  "error"
                )
              }
            }}
          >
            SIM, REMOVER
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ManagersList
