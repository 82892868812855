import React, { useEffect, useRef, useState } from "react"
import Header from "../../components/Header/Header"
import locationService from "../../services/LocationService"
import userService from "../../services/UserService"
import "bootstrap-icons/font/bootstrap-icons.css"
import { UserDTO } from "../../dtos/user.dto"
import "./CreateLocation.scss"
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner"
import { useNavigate } from "react-router-dom"
import Joi from "joi"
import ModalResponsibleRegister from "../../components/ModalResponsibleRegister/ModalResponsibleRegister"
import swal from "sweetalert"
import { CreateLocationDto } from "../../dtos/location.dto"

interface Errors {
  name?: string
  managerId?: number
  cep?: string
  address?: string
  addressNumber?: string
  state?: string
  city?: string
}

const CreateLocation = () => {
  const navigate = useNavigate()
  const [name, setName] = useState("")
  const [managerId, setManagerId] = useState(0)
  const [cep, setCep] = useState("")
  const [address, setAddress] = useState("")
  const [addressNumber, setAddressNumber] = useState("")
  const [state, setState] = useState("")
  const [city, setCity] = useState("")
  const [users, setUsers] = useState<UserDTO[]>([])
  const [addressAdditional, setAddressAdditional] = useState("")
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [showModal, setShowModal] = useState(false)
  const [errors, setErrors] = useState<Errors>({})
  const selectRef = useRef<HTMLSelectElement>(null)

  useEffect(() => {
    if (selectRef.current) {
      selectRef.current.value = managerId.toString()
    }
  }, [managerId])

  const loadUsers = () => {
    userService
      .getUsers()
      .then((data) => setUsers(data))
      .catch((error) => console.error(error))
  }

  useEffect(() => {
    loadUsers()
  }, [])

  const schema = Joi.object({
    name: Joi.string().required().messages({
      "string.empty": "O nome é obrigatório",
    }),
    managerId: Joi.number().min(1).required().messages({
      "number.min": "O responsável é obrigatório",
      "any.required": "O responsável é obrigatório",
    }),
    cep: Joi.string()
      .pattern(new RegExp("^[0-9]{5}-?[0-9]{3}$"))
      .required()
      .messages({
        "string.empty": "O CEP é obrigatório",
        "string.pattern.base": "O CEP é inválido",
      }),
    address: Joi.string().required().messages({
      "string.empty": "O endereço é obrigatório",
    }),
    addressNumber: Joi.string().required().messages({
      "string.empty": "O número é obrigatório",
    }),
    state: Joi.string().required(),
    city: Joi.string().required(),
  })

  const validateForm = () => {
    const { error } = schema.validate(
      {
        name,
        managerId,
        cep,
        address,
        addressNumber,
        state,
        city,
      },
      { abortEarly: false }
    )

    if (error) {
      setErrors(
        error.details.reduce(
          (acc: Record<string, string>, curr: Joi.ValidationErrorItem) => {
            acc[curr.path[0]] = curr.message
            return acc
          },
          {}
        )
      )
      return false
    }
    setErrors({})
    return true
  }

  if (isLoading) {
    return <LoadingSpinner />
  }

  const handleCepChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCep(event.target.value)

    const resultViaCep = await locationService.getAdressViaCep(
      event.target.value
    )

    setAddress(resultViaCep.logradouro)
    setState(resultViaCep.uf)
    setCity(resultViaCep.localidade)
    setAddressAdditional(resultViaCep.complemento)
  }

  const handleShow = () => setShowModal(true)

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    const isValid = validateForm()

    if (!isValid) {
      swal(
        "Atenção!",
        "Todos os campos devem ser preenchidos corretamente.",
        "error"
      )
      return
    }

    const locationData: CreateLocationDto = {
      name,
      managerId,
      cep,
      address,
      addressNumber,
      addressState: state,
      addressCity: city,
      addressAdditional,
    }

    try {
      setIsLoading(true)
      await locationService.create(locationData)

      setName("")
      setCep("")
      setAddress("")
      setAddressAdditional("")
      setCity("")
      setAddressNumber("")
      setState("")
      setManagerId(0)
      swal("Concluído!", "Negócio cadastrado com sucesso.", "success").then(
        () => {
          navigate(-1)
        }
      )
    } catch (error) {
      console.error(error)
      swal(
        "Erro!",
        "Ocorreu um erro ao cadastrar o negócio. Tente de novo.",
        "error"
      )
    } finally {
      setIsLoading(false)
    }
  }

  const handleCancel = () => {
    setName("")
    setCep("")
    setAddress("")
    setAddressAdditional("")
    setCity("")
    setAddressNumber("")
    setState("")
    setManagerId(0)
    setErrors({})

    navigate(-1)
  }

  return (
    <>
      <Header />
      <div className="bg-gray">
        <div
          className="container py-5 bg-white"
          style={{ width: "60%", margin: "0 auto" }}
        >
          <div className="container-fluid" style={{ maxWidth: "860px" }}>
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="businessName" className="form-label">
                  Nome do Negócio
                </label>
                <input
                  type="text"
                  id="businessName"
                  className="form-control input-light-placeholder"
                  placeholder="Insira o nome do negócio"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  onInput={() =>
                    setErrors((prev) => ({ ...prev, name: undefined }))
                  }
                />
                {errors.name && (
                  <p style={{ color: "darkred" }}>*{errors.name}</p>
                )}
              </div>

              <div className="mb-3 select-with-icon">
                <label htmlFor="responsibleName" className="form-label">
                  Responsável pelo Negócio
                </label>
                <div className="input-group">
                  <span className="input-group-text icon-no-background">
                    <i className="bi bi-search"></i>
                  </span>
                  <select
                    ref={selectRef}
                    id="responsibleName"
                    className={`form-control input-light-placeholder select-with-padding select-border-radius`}
                    onChange={(e) => {
                      const value = e.target.value
                      if (value === "") {
                        setManagerId(-1)
                      } else {
                        setManagerId(Number(value))
                      }
                    }}
                    onInput={() =>
                      setErrors((prev) => ({ ...prev, managerId: undefined }))
                    }
                  >
                    <option value="">Selecione um usuário</option>
                    {users.map((user, index) => (
                      <option key={index} value={user.id}>
                        {user.name}
                      </option>
                    ))}
                  </select>
                </div>

                {errors.managerId && (
                  <p style={{ color: "darkred" }}>*{errors.managerId}</p>
                )}
              </div>

              <button
                type="button"
                className="btn btn-outline-primary mb-3"
                onClick={handleShow}
              >
                CADASTRAR RESPONSÁVEL
              </button>

              <div className="mb-3">
                <label htmlFor="cep" className="form-label">
                  CEP
                </label>
                <input
                  type="text"
                  id="cep"
                  className="form-control input-light-placeholder"
                  placeholder="00000-000"
                  value={cep}
                  onChange={(event) => setCep(event.target.value)}
                  onBlur={(event) => {
                    const cepPattern = /^\d{8}$|^\d{5}-\d{3}$/
                    if (cepPattern.test(event.target.value)) {
                      handleCepChange(event)
                    } else {
                      setErrors((prev) => ({
                        ...prev,
                        cep: "Formato de CEP inválido",
                      }))
                      document.getElementById("cep")?.focus()
                    }
                  }}
                  onInput={() =>
                    setErrors((prev) => ({ ...prev, cep: undefined }))
                  }
                />
                {errors.cep && (
                  <p style={{ color: "darkred" }}>*{errors.cep}</p>
                )}
              </div>

              <div className="row mb-3">
                <div className="col">
                  <label htmlFor="address" className="form-label">
                    Endereço
                  </label>
                  <input
                    type="text"
                    id="address"
                    className="form-control input-light-placeholder"
                    placeholder="Rua, avenida, etc."
                    value={address}
                    onChange={(event) => setAddress(event.target.value)}
                    onInput={() =>
                      setErrors((prev) => ({ ...prev, address: undefined }))
                    }
                  />
                  {errors.address && (
                    <p style={{ color: "darkred" }}>*{errors.address}</p>
                  )}
                </div>

                <div className="col-3">
                  <label htmlFor="number" className="form-label">
                    Número
                  </label>
                  <input
                    type="text"
                    id="number"
                    className="form-control input-light-placeholder"
                    placeholder="Número do local"
                    value={addressNumber}
                    onChange={(e) => setAddressNumber(e.target.value)}
                    onInput={() =>
                      setErrors((prev) => ({
                        ...prev,
                        addressNumber: undefined,
                      }))
                    }
                  />
                  {errors.addressNumber && (
                    <p style={{ color: "darkred" }}>*{errors.addressNumber}</p>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-2">
                  <label htmlFor="state" className="form-label">
                    Estado
                  </label>
                  <input
                    type="text"
                    id="state"
                    className="form-control input-light-placeholder"
                    value={state}
                    readOnly
                    disabled
                    onChange={(e) => setState(e.target.value)}
                    onInput={() =>
                      setErrors((prev) => ({ ...prev, state: undefined }))
                    }
                  />
                </div>

                <div className="col">
                  <label htmlFor="city" className="form-label">
                    Cidade
                  </label>
                  <input
                    type="text"
                    id="city"
                    className="form-control input-light-placeholder"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    onInput={() =>
                      setErrors((prev) => ({ ...prev, namecity: undefined }))
                    }
                    readOnly
                    disabled
                  />
                </div>

                <div className="col-4">
                  <label htmlFor="complement" className="form-label">
                    Complemento (opcional)
                  </label>
                  <input
                    type="text"
                    id="complement"
                    className="form-control input-light-placeholder"
                    placeholder="Insira um complemento"
                    value={addressAdditional}
                    onChange={(e) => setAddressAdditional(e.target.value)}
                    onInput={() =>
                      setErrors((prev) => ({ ...prev, complement: undefined }))
                    }
                  />
                </div>
              </div>

              <div className="d-flex justify-content-center mt-5">
                <button
                  type="button"
                  className="btn btn-secondary"
                  style={{ marginRight: "20px" }}
                  onClick={handleCancel}
                >
                  CANCELAR
                </button>
                <button type="submit" className="btn btn-primary">
                  CADASTRAR NEGÓCIO
                </button>
              </div>
            </form>

            <ModalResponsibleRegister
              show={showModal}
              onClose={() => {
                setShowModal(false)
                loadUsers()
              }}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default CreateLocation
