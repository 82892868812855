import React, { useEffect, useState } from "react"
import Header from "../../components/Header/Header"
import { UserDTO } from "../../dtos/user.dto"
import shopkeeperService from "../../services/ShopkeeperService"
import { useNavigate, useParams } from "react-router-dom"
import "./EditShopkeeper.scss"
import { IShopkeeper } from "../../shared/interfaces/IShopkeeper"
import Joi from "joi"
import swal from "sweetalert"

interface Errors {
  shopkeeperName?: string
  shopkeeperEmail?: string
  shopkeeperPassword?: string
  shopkeeperConfirmPassword?: string
  shopkeeperPhone?: string
}

const EditShopkeeper = () => {
  const { shopkeeperId } = useParams()
  const navigate = useNavigate()
  const [shopkeeperName, setShopkeeperName] = useState("")
  const [shopkeeperEmail, setShopkeeperEmail] = useState("")
  const [shopkeeperPassword, setShopkeeperPassword] = useState("")
  const [shopkeeperConfirmPassword, setShopkeeperConfirmPassword] = useState("")
  const [shopkeeperPhone, setShopkeeperPhone] = useState("")
  const [errors, setErrors] = useState<Errors>({})

  const getShopkeeper = async () => {
    const shopkeeper: IShopkeeper = await shopkeeperService.getById(
      +shopkeeperId!
    )

    setShopkeeperName(shopkeeper.user.name)
    setShopkeeperEmail(shopkeeper.user.email)
    setShopkeeperPhone(shopkeeper.user.phone ?? "")
  }

  useEffect(() => {
    getShopkeeper()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shopkeeperId])

  const schema = Joi.object({
    shopkeeperName: Joi.string().required().messages({
      "string.empty": "O nome é obrigatório",
    }),

    shopkeeperEmail: Joi.string()
      .email({ tlds: { allow: false } })
      .messages({
        "string.email": "O email não é válido",
      }),

    shopkeeperPassword: Joi.string().min(6).allow("").messages({
      "string.min": "A senha deve ter pelo menos 6 caracteres",
    }),
    shopkeeperConfirmPassword: Joi.string()
      .valid(Joi.ref("shopkeeperPassword"))
      .allow("")
      .messages({
        "any.only": "As senhas devem coincidir",
      }),

    shopkeeperPhone: Joi.string()
      .pattern(new RegExp("^[0-9]{10,11}$"))
      .required()
      .messages({
        "string.empty": "O telefone é obrigatório",
        "string.pattern.base": "O telefone não é válido",
      }),
  })

  const validateForm = () => {
    const { error } = schema.validate(
      {
        shopkeeperName,
        shopkeeperEmail,
        shopkeeperPassword,
        shopkeeperConfirmPassword,
        shopkeeperPhone,
      },
      { abortEarly: false }
    )

    if (shopkeeperPassword === "" && shopkeeperConfirmPassword === "") {
      if (error) {
        error.details = error.details.filter(
          (detail) =>
            detail.path[0] !== "shopkeeperPassword" &&
            detail.path[0] !== "shopkeeperConfirmPassword"
        )
      }
    }

    if (error) {
      setErrors(
        error.details.reduce(
          (acc: Record<string, string>, curr: Joi.ValidationErrorItem) => {
            acc[curr.path[0]] = curr.message
            return acc
          },
          {}
        )
      )
      return false
    }
    setErrors({})
    return true
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    const isValid = validateForm()

    if (!isValid) {
      swal(
        "Atenção!",
        "Todos os campos devem ser preenchidos corretamente.",
        "error"
      )
      return
    }

    const updatedUser: Partial<UserDTO> = {
      name: shopkeeperName,
      email: shopkeeperEmail,
      phone: shopkeeperPhone,
    }

    if (shopkeeperPassword) updatedUser.password = shopkeeperPassword

    try {
      await shopkeeperService.updateUser(+shopkeeperId!, updatedUser)

      setShopkeeperName("")
      setShopkeeperEmail("")
      setShopkeeperPassword("")
      setShopkeeperConfirmPassword("")
      setShopkeeperPhone("")
      swal("Sucesso!", "Responsável atualizado com sucesso!", "success").then(
        () => {
          navigate(-1)
        }
      )
    } catch (error) {
      console.error(error)
      swal(
        "Erro!",
        "Ocorreu um erro ao atualizar o responsável. Tente de novo.",
        "error"
      )
    }
  }

  const handleCancel = () => {
    setShopkeeperName("")
    setShopkeeperEmail("")
    setShopkeeperPassword("")
    setShopkeeperConfirmPassword("")
    setShopkeeperPhone("")
    navigate(-1)
  }

  return (
    <>
      <Header />
      <div className="bg-gray">
        <div className="container containerStyle py-5 bg-white">
          <h2>Editar Lojista</h2>
          <div className="container-fluid containerFluidStyle">
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Nome completo
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder="Insira o nome do lojista"
                  value={shopkeeperName}
                  onChange={(e) => setShopkeeperName(e.target.value)}
                  onInput={() =>
                    setErrors((prev) => ({
                      ...prev,
                      shopkeeperEmail: undefined,
                    }))
                  }
                />
                {errors.shopkeeperName && (
                  <p style={{ color: "darkred" }}>*{errors.shopkeeperName}</p>
                )}
              </div>

              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  E-mail
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder="Insira o email do lojista"
                  value={shopkeeperEmail}
                  onChange={(e) => setShopkeeperEmail(e.target.value)}
                  onInput={() =>
                    setErrors((prev) => ({
                      ...prev,
                      shopkeeperEmail: undefined,
                    }))
                  }
                />
                {errors.shopkeeperEmail && (
                  <p style={{ color: "darkred" }}>*{errors.shopkeeperEmail}</p>
                )}
              </div>
              <span className="passwords-div-label">
                Deixe em branco para não alterar
              </span>
              <div className="passwords-div">
                <div className="mb-3">
                  <label htmlFor="password" className="form-label">
                    Senha
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    placeholder="Insira a senha"
                    value={shopkeeperPassword}
                    onChange={(e) => setShopkeeperPassword(e.target.value)}
                    onInput={() =>
                      setErrors((prev) => ({
                        ...prev,
                        shopkeeperPassword: undefined,
                      }))
                    }
                  />
                  {errors.shopkeeperPassword && (
                    <p style={{ color: "darkred" }}>
                      *{errors.shopkeeperPassword}
                    </p>
                  )}
                </div>

                <div className="mb-3">
                  <label htmlFor="confirmPassword" className="form-label">
                    Digite a senha de novo
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="confirmPassword"
                    placeholder="Digite a senha de novo"
                    value={shopkeeperConfirmPassword}
                    onChange={(e) =>
                      setShopkeeperConfirmPassword(e.target.value)
                    }
                    onInput={() =>
                      setErrors((prev) => ({
                        ...prev,
                        shopkeeperConfirmPassword: undefined,
                      }))
                    }
                  />
                  {errors.shopkeeperConfirmPassword && (
                    <p style={{ color: "darkred" }}>
                      *{errors.shopkeeperConfirmPassword}
                    </p>
                  )}
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="phone" className="form-label">
                  Fone
                </label>
                <input
                  type="tel"
                  className="form-control"
                  id="phone"
                  placeholder="Insira o número de telefone"
                  value={shopkeeperPhone}
                  onChange={(e) => setShopkeeperPhone(e.target.value)}
                  onInput={() =>
                    setErrors((prev) => ({
                      ...prev,
                      shopkeeperPhone: undefined,
                    }))
                  }
                />
                {errors.shopkeeperPhone && (
                  <p style={{ color: "darkred" }}>*{errors.shopkeeperPhone}</p>
                )}
              </div>

              <div className="d-flex justify-content-center mt-5">
                <button
                  type="button"
                  className="btn btn-secondary"
                  style={{ marginRight: "20px" }}
                  onClick={handleCancel}
                >
                  CANCELAR
                </button>
                <button type="submit" className="btn btn-primary">
                  CONFIRMAR
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default EditShopkeeper
